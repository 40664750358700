/**
 * Mappa le opzioni della select per essere compatibili con il componente app-input
 * @param values valori da mappare
 * @param label chiave che rappresenta la label
 * @param value chiave che rappresenta il value
 */
export function mapSelectOptions(values: Array<any>, label: string, value: string) {
    return values.map(item => ({
        label: item[label],
        value: item[value]
    }))
}

/**
 * Funzione che aggiunge la voce indicata in un array
 * @param array
 * @param label label della voce da aggiungere
 * @param value value della voce da aggiungere
 * @private
 */
export function getSelectWithAdditionalField(array: Array<any>, label: string, value: any){
    return [{value, label}, ...array];
}