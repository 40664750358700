import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FormattedResponse } from '../models/formatted-response.model';
import { HttpContextConfig } from '../models/http-context-config';
import { CONFIG } from '../interceptors/token-interceptor.service';

const BASE_URL = `${environment.api_URL}`;
const ApiURL = `${environment.api_URL}/auth`;

const config = new HttpContextConfig();
config.noSpinner = true;
const context = new HttpContext().set(CONFIG, config);

@Injectable({
    providedIn: 'root'
})
export class AnagraficaService {
    constructor(private http: HttpClient) { }
    
    addUser(data: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/users`, data);
    }      
    
    editUser(id, data: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/users/${id}`, data);
    }    
    
    getUsers(params: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/users`, {params});
    }

    deleteMember(id: number, params? : any): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/users/${id}`, {params});
    }

    getUser(id: string, params? : any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/users/${id}`, {params});
    }
    
    getMembers(): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/elencoAnagrafiche`);
    }

    getStaffTeachers(): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/staffTeachers`);
    }

    deleteUser(id: number): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/deleteMemberbyId/${id}`);
    }
    
    getTable(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/tableMembers`, { params, context });
    }

    getStudent(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/students`, { params });
    }
    getDocents(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/teachers`, { params });
    }

    createSituazioneContabile(params?: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/createSituazioneContabile`, params);
    }

    getSituazioneContabile(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getSituazioneContabile`, { params });
    }

    editSituazioneContabile(params?: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/editSituazioneContabile`, params);
    }

    getAnagraficaById(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getAnagraficaById`, { params });
    }

    register(data: any): Observable<any> {
        return this.http.post<any>(`${ApiURL}/register`, data);
    }

    storeLingue(params?: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/storeLingue`, params);
    }

    editLingue(params?: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/editLingue`, params);
    }

    deleteLingue(params?: any): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/deleteLingue`, { params });
    }

    getLingue(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getLingue`, { params });
    }
    async getLingueByStudent(params?: any) {
        return await this.http.get<any>(`${BASE_URL}/getLingueByStudent`, { params, context }).toPromise();
    }

    getProfile(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/profile`, { params });
    }

    editProfile(params?: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/editProfile`, params);
    }

    editMail(params?: any): Observable<FormattedResponse> {
        return this.http.patch<FormattedResponse>(`${BASE_URL}/auth/user/change-email`, params);
    }

    checkUser(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/auth/checkUser`, { params });
    }

    lostPassword(params?: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/auth/lostPassword`, params);
    }

    editPassword(params?: any): Observable<FormattedResponse> {
        return this.http.patch<FormattedResponse>(`${BASE_URL}/auth/user/change-password`, params);
    }

    editImage(params?: any): Observable<FormattedResponse> {
        const formData = new FormData();
        formData.append('image', params.image);
        formData.append('id', params.id);
        return this.http.put<FormattedResponse>(`${BASE_URL}/editImage`, formData);
    }

    insertMessage(data: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/messages`, {data});
    }

    getMessage(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/messages`, { params });
    }

    patchMessage(id, params?: any): Observable<FormattedResponse> {
        return this.http.patch<FormattedResponse>(`${BASE_URL}/messages/${id}`, params);
    }

    deleteMessage(id): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/messages/${id}`);
    }

    getUserId(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/userId`, { params });
    }

    getMemberById(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getUserById`, { params });
    }

    getAllPayment(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getAllPayment`, { params });
    }

    getPagamentiStudente(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/pagamentiStudente`, { params });
    }

    editPayment(params?: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/editPayment`, params);
    }

    searchStudents(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/searchStudents`, { params, context });
    }

    searchAll(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/searchAll`, { params, context });
    }

    getTotalHoursDocent(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getTotalHoursDocent`, { params });
    }

    getTotalHoursClass(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getTotalHoursClass`, { params });
    }

    exportData(params?: any, options?: { responseType: 'blob' }): Observable<any> {
        return this.http.post(`${BASE_URL}/export`, params, { responseType: 'blob' as 'json' });
    }

    /*
    exportData(params?: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/export`, params);
    }
  */

    searchAnagrafica(params?: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/users/search`, params, {context});
    }

    setRepresentative(data: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/representatives/set`, data);
    }    
    
    deleteRepresentative(data: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/representatives/delete`, data);
    }
}