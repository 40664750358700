<div class="container">
    <div class="altezza-page px-0 mx-0" id="wrap2">
        <div class="row px-0 mx-0 margine-sup">
            <div class="section__header">
                <div class="row my-2 ">
                    <div class="col-12 d-flex justify-content-between align-items-center">
                        <div>
                            <span class="back-button" (click)="goBack()" onKeyDown="">Torna indietro</span>
                            <h1 class="mb-0 pb-0">
                                {{ action == 'store' ? 'Crea' : 'Modifica'}} {{ type }}
                            </h1>
                        </div>

                        <button *ngIf="action == 'update'" class="btn color-butt" (click)="print()">
                            Stampa
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section__body p-5">
        <preventivi-contratti-form
            [type]="type"
            [action]="action">
        </preventivi-contratti-form>
    </div>
</div>
