import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PasswordCriteriasComponent } from "./password-criterias/password-criterias.component";
import {InputComponent} from "./input/input.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MaterialExampleModule} from "../material.module";
import {UserFormComponent} from "./user-form/user-form.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { FormatoItalianoPipe } from './formato-italiano.pipe';

@NgModule({
  declarations: [
    PasswordCriteriasComponent,
    InputComponent,
    UserFormComponent,
    FormatoItalianoPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MaterialExampleModule,
    MatSlideToggleModule,
  ],  
  exports: [
    PasswordCriteriasComponent,
    InputComponent,
    UserFormComponent
  ],
})
export class UtilitiesModule {}
