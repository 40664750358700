import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatoItaliano'
})
export class FormatoItalianoPipe implements PipeTransform {

  transform(value: Date): string {
    if (!value) {
      return '';
    }
    return moment(value).format('DD/MM/YYYY');
  }

}
