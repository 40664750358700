import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {AnagraficaService} from "../services/anagraphic.service";
import {Subject} from "rxjs";
import {DateAdapter} from "@angular/material/core";
import {ClasseService} from "../services/class.service";
import {MatDialog} from "@angular/material/dialog";
import {CostManagementService} from "../services/costManagement.service";
import {getSelectWithAdditionalField, mapSelectOptions} from "../utilities/input-utilities";
import {checkFormFields, checkIncludedFields, formatDateField} from "../utilities/form-utilities";
import {getNoteFlags, getPaymentTypologies, getStatiPreventivo} from "../utilities/constants";
import {isNumeric} from "rxjs/internal-compatibility";
import {PreventiviService} from "../services/preventivi.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'preventivi-contratti-form',
  templateUrl: './preventivi-contratti-form.component.html',
  styleUrls: ['./preventivi-contratti-form.component.scss']
})
export class PreventiviContrattiFormComponent implements OnInit, OnDestroy {
  readonly checkIncludedFields = checkIncludedFields;
  @ViewChild('confirmModal') confirmModal: TemplateRef<any>;
  @Input() type: string = '';
  @Input() action: string = '';

  private destroy$: Subject<void> = new Subject<void>();
  form: FormGroup = this.initForm();
  students: Array<any> = [];
  owners: Array<any> = [];
  languages: Array<any> = [];
  levels: Array<any> = [];
  sconto_materiale_didattico: Array<any> = [];
  sconto_tassa_iscrizione: Array<any> = [];
  isOwnerParentOrCompany: boolean = false;
  readonlyState: boolean = false;
  payment_typologies = getPaymentTypologies();
  stati_preventivo = getStatiPreventivo();
  note_flags = getNoteFlags();
  confirm_modal_info = {
    title: null,
    text: null,
    button_class: null,
    callback: () => {}
  }
  basePriceMateriale: number;
  basePriceTassaIscrizione: number;
  
  constructor(
      private _dateAdapter: DateAdapter<Date>,
      public _dialog: MatDialog,
      private _anagrafica: AnagraficaService,
      private _class: ClasseService,
      private _costManagement: CostManagementService,
      private _preventivi: PreventiviService,
      private _router: Router,
      private _toastr: ToastrService,
      private _configuration: ConfigurationService
  ) {
    this._dateAdapter.setLocale('it-IT');
  }

  ngOnInit(): void {
    this.getOwners();
    this.getStaticLanguagesLevels();
    this.getCosts();
    this.getConfigurationCost();

    setTimeout(() => {
      if(this.action == 'update'){
        this.getData();
      }
    }, 500);

    this.listenToFormChanges();
    this.listenToLanguagesChanges();
  }

  /**
   * Ritorna i dati in base alla tipologia
   * @private
   */
  private getData(){
    if(this.type == 'preventivo'){
      this.getPreventivo();
    }
    else if(this.type == 'contratto'){
      this.getContratto();
    }
  }

  /**
   * Recupera i dati del preventivo
   * @private
   */
  private getPreventivo(){
    this._preventivi.getPreventivo(this._router.url.split('/')[2]).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.patchForm(response.result);
      }
    })
  }

  /**
   * Recupera i dati del contratto
   * @private
   */
  private getContratto(){
    this._preventivi.getContratto(this._router.url.split('/')[2]).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.readonlyState = response.result.stato == 'APPROVATO';
        this.patchForm(response.result);
      }
    })
  }

  /**
   * Patcha il form con i dati
   * @private
   */
  private patchForm(value){
    let array_lingue = JSON.parse(value.array_lingue)

    this.form.patchValue({
      id: value.id,
      owner: value.owner,
      titolo: value.titolo,
      data_scadenza: value.data_scadenza,
      consultant: value.consultant,
      method: value.method,
      note: value.note,
      sconto_materiale_didattico: value.sconto_materiale_didattico,
      tassa_iscrizione: value.tassa_iscrizione,
      sconto_tassa_iscrizione: value.sconto_tassa_iscrizione,
      costo_materiale_didattico: value.costo_materiale_didattico,
      prima_rata: value.prima_rata,
      importo_rata: value.importo_rata,
      numero_rate: value.numero_rate,
      rate_rimanenti:value.rate_rimanenti,
      acconto: value.acconto,
      totale_saldare: value.totale_saldare,
      stato: value.stato,
      altre_note: value.altre_note ?? null,
      note_one: value.note_documento?.find((e) => e.type_id == 1)?.value ?? false,
      note_second: value.note_documento?.find((e) => e.type_id == 2)?.value ?? false,
      note_third: value.note_documento?.find((e) => e.type_id == 3)?.value ?? false,
      note_four: value.note_documento?.find((e) => e.type_id == 4)?.value ?? false,
      data_inizio_pagamento: value.data_inizio_pagamento ?? null
    });

    this.languagesFormArray.clear();

    array_lingue.forEach(language => {
      this.addLanguagesFormArrayItem(language?.students);
    })

    this.form.get('array_lingue').patchValue(array_lingue);
  }

  /**
   * Inizializzazione del form principale
   * @private
   */
  private initForm(){
    return new FormGroup({
      id: new FormControl(null),
      owner: new FormControl(null),
      titolo: new FormControl(null),
      data_scadenza: new FormControl(null),
      consultant: new FormControl(null),
      note: new FormControl(null),
      array_lingue: new FormArray(this.action == 'store' ? [this.initLanguagesFormArray()] : []),
      method: new FormControl(null),
      sconto_materiale_didattico: new FormControl(null),
      costo_materiale_didattico: new FormControl(null),
      sconto_tassa_iscrizione: new FormControl(null),
      tassa_iscrizione: new FormControl(null),
      totale_materiale_didattico: new FormControl(0),
      totale_tassa_iscrizione: new FormControl(0),
      totale_saldare: new FormControl(null),
      costo_totale_corsi: new FormControl(null),
      prima_rata: new FormControl(null),
      numero_rate: new FormControl(null),
      importo_rata: new FormControl(null),
      rate_rimanenti: new FormControl(null),
      acconto: new FormControl(null),
      stato: new FormControl(null),
      altre_note: new FormControl(null),
      note_one: new FormControl(null),
      note_second: new FormControl(null),
      note_third: new FormControl(null),
      note_four: new FormControl(null),
      data_inizio_pagamento: new FormControl(null)
    });
  }

  /**
   * Inizializzazione del FormArray delle lingue
   * @private
   */
  private initLanguagesFormArray(students = null){
    let formGroup = new FormGroup({
      lingua: new FormControl(null),
      livello: new FormControl(null),
      tipologia_corso: new FormControl(null),
      totale_ore: new FormControl(null),
      frequenza: new FormControl(null),
      durata: new FormControl(null),
      students: new FormArray([]),
      students_number: new FormControl(null),
      students_search: new FormControl(null),
      totale_costo_corso: new FormControl(null),
      check_sconto_mensile: new FormControl(null),
      sconto: new FormControl(null),
      titolo_sconto_mensile: new FormControl(null),
      cifra_sconto_mensile: new FormControl(null),
    });
    
    if(students){
      const studentsFormArray = formGroup.get('students') as FormArray;

      students.forEach(student => {
        studentsFormArray.push(new FormGroup({
          label: new FormControl(student.label),
          value: new FormControl(student.value)
        }));
      })
    }
    
    return formGroup;
  }

  /**
   * Intercetta i cambiamenti del form
   * @private
   */
  private listenToFormChanges(){
    //quando l'utente compila il campo Intestatario ritorno i risultati
    //se l'intestatario è un genitore o un'azienda facciamo comparire i campi aggiuntivi
    this.form.get('owner').valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000)).subscribe({
      next: value => {
        if(typeof value == 'string'){
          this.isOwnerParentOrCompany = false;
          this.getOwners();
        }
        else {
          this.isOwnerParentOrCompany = value.role == 6 || value.role == 7;
          this.getStudents();
        }
      }
    })

    //l'input Quantità materiale didattico deve essere compilabile solo con Sconto materiale didattico = Nessuno sconto
    this.form.get('sconto_materiale_didattico').valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {    
        let reset_fields = value != 0 ? ['costo_materiale_didattico'] : [];
        checkFormFields(this.form, [], [], reset_fields);

        if (value == 0) {
          this.form.patchValue({ costo_materiale_didattico: 1 });
        }

        this.getTotaleMaterialeDidattico();
      }
    })

    //l'input Quantità tassa iscrizione deve essere compilabile solo con Sconto tassa iscrizione = Nessuno sconto
    this.form.get('sconto_tassa_iscrizione').valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        let reset_fields = value != 0 ? ['tassa_iscrizione'] : [];
        checkFormFields(this.form, [], [], reset_fields);

        if (value == 0) {
          this.form.patchValue({ tassa_iscrizione: 1 });
        }
        
        this.getTotaleTassaIscrizione();
      }
    })    
    
    //nel caso Sconto materiale didattico = Nessuno sconto, calcoliamo il totale materiale didattico in base alle quantità
    this.form.get('costo_materiale_didattico').valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => this.getTotaleMaterialeDidattico()
    })

    //nel caso Sconto tassa iscrizione = Nessuno sconto, calcoliamo il totale tassa iscrizione in base alle quantità
    this.form.get('tassa_iscrizione').valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => this.getTotaleTassaIscrizione()
    })    
    
    //calcolo il totale da saldare al cambio di metodo
    this.form.get('method').valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.resetPaymentFields();
        this.getTotaleDaSaldare()
      }
    })    
    
    //calcolo l'importo delle rate
    this.form.get('numero_rate').valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => this.getTotaleDaSaldare()
    })    
    
    //in modalità 4
    this.form.get('acconto').valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => this.getTotaleDaSaldare()
    })
  }

  /**
   * Intercetta i cambiamenti del FormArray delle lingue
   * @private
   */
  private listenToLanguagesChanges(){
    //quando l'utente compila il campo Ricerca studenti ritorno i risultati
    this.languagesFormArray.controls.forEach((language: FormGroup) => {
      language.get('students_search').valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000)).subscribe({
        next: value => {
          if(typeof value == 'string') {
            this.getStudents(value)
          }
        }
      })

      language.get('totale_costo_corso').valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000)).subscribe({
        next: () => this.getTotaleDaSaldare()
      })

      language.get('cifra_sconto_mensile').valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000)).subscribe({
        next: () => this.getTotaleDaSaldare()
      })

      language.get('sconto').valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000)).subscribe({
        next: () => this.getTotaleDaSaldare()
      })
    });
  }

  /**
   * Ritorna l'elenco di studenti, genitori e aziende
   * @private
   */
  private getOwners(){
    let params = { search: this.form.get('owner').value, role: [5, 6, 7] };
    this._anagrafica.searchAnagrafica(params).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.owners = response.result;
    })
  }    

  /**
   * Patcha il form array delle lingue di partenza dello studente
   * @param student 
   */
  addStudentLanguage(student: any) {
    if (student.lingue && student.lingue.length > 0) {
      const languagesFormArray = this.form.get('array_lingue') as FormArray;
      languagesFormArray.clear();

      student.lingue.forEach((lingua: any) => {
        const languageFormGroup = new FormGroup({
          lingua: new FormControl(lingua.ling_richiesta),
          livello: new FormControl(lingua.ling_partenza),
          tipologia_corso: new FormControl(null),
          totale_ore: new FormControl(null),
          frequenza: new FormControl(null),
          durata: new FormControl(null),
          students: new FormArray([]),
          students_number: new FormControl(null),
          students_search: new FormControl(null),
          totale_costo_corso: new FormControl(null),
          check_sconto_mensile: new FormControl(null),
          sconto: new FormControl(null),
          titolo_sconto_mensile: new FormControl(null),
          cifra_sconto_mensile: new FormControl(null),
        });
        languagesFormArray.push(languageFormGroup);
      });

      this.listenToLanguagesChanges();
    }
  }

  
  /**
   * Ritorna l'elenco di studenti, genitori e aziende
   * @private
   */
  private getStudents(search: string = null){
    let params = { search: search, role: [5], id: this.isOwnerParentOrCompany ? this.form.get('owner').value.value : null };
    this._anagrafica.searchAnagrafica(params).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.students = response.result;
    })
  }  
  
  /**
   * Ritorna l'elenco delle lingue e dei livelli da DB
   * @private
   */
  private getStaticLanguagesLevels(){
    this._class.getLangLevelTypology().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.languages = mapSelectOptions(res.lingue, 'nome_lingua', 'id');
      this.levels = mapSelectOptions(res.livelli, 'nome', 'id');
    })
  }

  /**
   * Ritorna gli sconti riguardanti materiale didattico e tassa di iscrizione
   * @private
   */
  private getCosts(){
    this._costManagement.getCost().subscribe({
      next: response => {
        let materiale_didattico = response.result.filter(item => item.type == 2).map(item => ({
          label: item.testo_scontistica,
          value: item.id,
          costo: item.valore,
          quantity: item.quantity
        }));
        let tassa_iscrizione = response.result.filter(item => item.type == 1).map(item => ({
          label: item.testo_scontistica,
          value: item.id,
          costo: item.valore,
          quantity: item.quantity
        }));
        this.sconto_materiale_didattico = getSelectWithAdditionalField(materiale_didattico, 'Nessuno sconto', 0);
        this.sconto_tassa_iscrizione = getSelectWithAdditionalField(tassa_iscrizione, 'Nessuno sconto', 0);
      }
    })
  }

  /**
   * Aggiunge un nuovo elemento al FormArray delle lingue
   */
  addLanguagesFormArrayItem(students = null) {
    this.languagesFormArray.push(this.initLanguagesFormArray(students));
    this.listenToLanguagesChanges();
  }

  /**
   * Rimuove l'elemento dal FormArray delle lingue
   */
  removeLanguagesFormArrayItem(i) {
    this.languagesFormArray.removeAt(i);
    this._dialog.closeAll();
    this.getTotaleDaSaldare();
    this.simulateClick();
  }

  /**
   * Aggiunge uno studente
   */
  addStudentsFormArrayItem(i: number, students = null){
    const languagesFormArray = this.languagesFormArray.at(i);
    const student = students ?? languagesFormArray.get('students_search').value;
    const studentsFormArray = languagesFormArray.get('students') as FormArray;

    if(studentsFormArray.value.find(item => item.value == student.value)){
      this._toastr.error('Studente già presente');
      return;
    }

    studentsFormArray.push(new FormGroup({
      label: new FormControl(student.label),
      value: new FormControl(student.value)
    }));

    languagesFormArray.get('students_search').reset();
  }

  /**
   * Rimuove l'elemento dal FormArray delle lingue
   */
  removeStudentsFormArrayItem(i, j) {
    (this.languagesFormArrayByIndex(i).get('students') as FormArray).removeAt(j);
    this._dialog.closeAll();
    this.simulateClick();
  }

  /**
   * Apertura della modale di conferma per eliminare una lingua
   * @param i indice del FormArray array_lingue
   */
  openLanguagesConfirmModal(i: number) {
    this.confirm_modal_info.title = 'Elimina lingua';
    this.confirm_modal_info.text = 'Sei sicuro di voler procedere?';
    this.confirm_modal_info.button_class = 'btn-danger';
    this.confirm_modal_info.callback = () => this.removeLanguagesFormArrayItem(i)
    this.openConfirmModal();
  }

  /**
   * Apertura della modale di conferma per eliminare una lingua
   * @param i indice del FormArray array_lingue
   */
  openStudentsConfirmModal(i: number, j) {
    this.confirm_modal_info.title = 'Elimina studente';
    this.confirm_modal_info.text = 'Sei sicuro di voler procedere?';
    this.confirm_modal_info.button_class = 'btn-danger';
    this.confirm_modal_info.callback = () => this.removeStudentsFormArrayItem(i, j);
    this.openConfirmModal();
  }

  /**
   * Apertura della modale di conferma generica
   * @private
   */
  private openConfirmModal(){
    this._dialog.open(this.confirmModal, {
      height: '16rem',
      width: '30rem'
    });
  }
  
  /**
   * Calcola il totale materiale didattico
   */
  getTotaleMaterialeDidattico(){
    let sale = this.form.get('sconto_materiale_didattico').value;
    let counter = this.form.get('costo_materiale_didattico').value;
    let array = this.sconto_materiale_didattico;
    let price = this.calculatePrice(sale, this.basePriceMateriale, counter, array);

    this.form.get('totale_materiale_didattico').setValue(price, {emitEvent: false});
    this.getTotaleDaSaldare();
  }

  /**
   * Calcola il totale tassa iscrizione
   */
  getTotaleTassaIscrizione(){
    let sale = this.form.get('sconto_tassa_iscrizione').value;
    let counter = this.form.get('tassa_iscrizione').value;
    let array = this.sconto_tassa_iscrizione;
    let price = this.calculatePrice(sale, this.basePriceTassaIscrizione, counter, array);
    
    this.form.get('totale_tassa_iscrizione').setValue(price);
    this.getTotaleDaSaldare();
  }

  /**
   * Calcola il totale da saldare
   */
  getTotaleDaSaldare(){
    const method = this.form.get('method').value;
    const materiale_didattico = this.form.get('totale_materiale_didattico').value;
    const tassa_iscrizione = this.form.get('totale_tassa_iscrizione').value;
    const acconto = this.form.get('acconto').value ?? 0;
    const numero_rate = this.form.get('numero_rate').value ?? 0;
    const rate_rimanenti = numero_rate > 0 ? numero_rate - 1 : 0;
    let costo_corsi: number = 0;

    this.form.get('array_lingue').value.forEach(lingua => {
      if(lingua.totale_costo_corso && !lingua.cifra_sconto_mensile && !lingua.sconto) {
        costo_corsi += Number(lingua.totale_costo_corso);
      }
      else if(lingua.sconto){
        costo_corsi += this.calculatePercentage(lingua.totale_costo_corso, lingua.sconto);
      }
      else if(lingua.cifra_sconto_mensile){
        costo_corsi += Number(lingua.totale_costo_corso - lingua.cifra_sconto_mensile);
      }
    })
    
    this.form.get('costo_totale_corsi').setValue(costo_corsi);
    let importo = costo_corsi / numero_rate;

    switch (parseInt(method)) {
      //caso unica soluzione: il totale da saldare è la somma di tutti i costi
      case 1:
        this.form.get('totale_saldare').setValue((materiale_didattico + tassa_iscrizione + costo_corsi).toFixed(2));
        break;

      //caso preventivo con acconto: dal totale da saldare si escludono materiale didattico e iscrizione perchè presenti in Prima quota
      case 2:
        this.form.get('totale_saldare').setValue((costo_corsi).toFixed(2));
        break;
        
      case 3:
        this.form.get('totale_saldare').setValue((importo * rate_rimanenti).toFixed(2));
        this.form.get('importo_rata').setValue((Number(importo)).toFixed(2));
        break;
        
      //caso acconto manuale: il totale da saldare è la differenza tra la somma di tutti i costi e l'acconto
      case 4:
        this.form.get('totale_saldare').setValue((costo_corsi - acconto).toFixed(2));
        break;
    }

    this.getImportoRata();
  }

  /**
   * Calcola l'importo della rata
   */
  getImportoRata(){
    const method = this.form.get('method').value;
    const rate = this.form.get('numero_rate').value;
    
    if(parseInt(method) == 3) {

        this.form.get('rate_rimanenti').setValue(rate > 0 ? rate - 1 : 0);
    }
    else{
      if(this.form.get('totale_saldare').value && rate) {
        let importo = this.form.get('totale_saldare').value / rate;
        this.form.get('importo_rata').setValue((Number(importo)).toFixed(2));
      }
      this.form.get('rate_rimanenti').setValue(rate);
    }
  
    this.getPrimaRata();
  }

  /**
   * Calcola l'importo della prima rata
   */
  getPrimaRata(){
    const method = this.form.get('method').value;
    const materiale_didattico = this.form.get('totale_materiale_didattico').value ?? 0;
    const tassa_iscrizione = this.form.get('totale_tassa_iscrizione').value ?? 0;
    const importo_rata = this.form.get('importo_rata').value ? Number(this.form.get('importo_rata').value) : 0;
    const acconto = this.form.get('acconto').value ?? 0;
    const materiale_iscrizioni = Number(tassa_iscrizione + materiale_didattico);
    let prima_rata = 0;

    switch (parseInt(method)) {
      case 2:
        prima_rata = materiale_iscrizioni;
        break;
      case 4:
        prima_rata = Number(acconto) + materiale_iscrizioni;
        break;
      case 3:
        prima_rata = isNumeric(importo_rata) ? materiale_iscrizioni + importo_rata : materiale_iscrizioni;
        break;
    }

    this.form.get('prima_rata').setValue(prima_rata.toFixed(2));
  }

  /**
   * Calcolo del costo scontato
   * @param total totale su cui applicare lo sconto
   * @param percentage percentuale dello sconto da applicare
   * @private
   */
  private calculatePercentage(total, percentage){
    let sconto = total * (Number(percentage) / 100);
    return total - sconto;
  }

  /**
   * Ritorna il costo totale
   * @param sale sconto
   * @param base_price prezzo base per un elemento
   * @param counter contatore di elementi
   * @param array dati in cui è presente il costo
   * @private
   */
  private calculatePrice(sale: number|string, base_price: number, counter: number, array: Array<any>){
    //nessuno sconto
    if(sale == 0 || sale == '0'){
      return base_price * (counter ?? 0);
    }
    //nessuna opzione selezionata
    else if(!sale){
      return 0;
    }
    //altre opzioni
    else {
      let item = array.find(item => item.value == sale);
      let discount = ((Number(base_price) * Number(item.costo)) / 100) * Number(item.quantity);
      return Math.floor((((Number(base_price) * Number(item.quantity)) - Number(discount)) * Math.pow(10, 2)) / Math.pow(10, 2));
    }
  }

  /**
   * Filtraggio delle date selezionabili 
   * @param d
   */
  scadenzaDatepickerFilter = (d: Date | null): boolean => {
    if(this.action == 'store'){
      const today = new Date().setHours(0, 0, 0, 0);
      const date = (d || new Date()).setHours(0, 0, 0, 0);
      return date >= today;  // Restituisce true solo se la data è futura
    }

    return true;
  };
  
  /**
   * Getter dell'i-esimo elemento del FormArray delle lingue
   */
  languagesFormArrayByIndex(i){
    return this.languagesFormArray.at(i);
  }

  /**
   * Getter del FormArray delle lingue
   */
  get languagesFormArray(): FormArray{
    return <FormArray>this.form.get('array_lingue');
  }

  /**
   * Controlla se l'autocomplete degli studenti è valido
   */
  checkAutocomplete(value: any) {
    return typeof value == 'string' || !value;
  }

  /**
   * Salva in base alla tipologia
   */
  onSave() {
    this.resetStudentsSearchFields();
    
    if(this.action == 'store'){
      this.confirm_modal_info.title = `Salva ${this.type}`;
      this.confirm_modal_info.text = `Sei sicuro di voler salvare il ${this.type}?`;
      this.confirm_modal_info.button_class = 'color-butt';
      this.confirm_modal_info.callback = () => this.type == 'preventivo' ? this.storePreventivo() : this.storeContratto();
    }
    else {
      this.confirm_modal_info.title = `Modifica ${this.type}`;
      this.confirm_modal_info.text = `Sei sicuro di voler modificare il ${this.type}?`;
      this.confirm_modal_info.button_class = 'color-butt';
      this.confirm_modal_info.callback = () => this.type == 'preventivo' ? this.updatePreventivo() : this.updateContratto();
    }

    this.openConfirmModal();
  }

  /**
   * Salvataggio del preventivo
   * @private
   */
  private storePreventivo(){
    formatDateField(this.form, ['data_scadenza']);
    this._preventivi.addPreventivo(this.form.value).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.onSuccess();
        this._router.navigate([`/gestione-preventivi/${response.result.id}`]);
      },
      error: response => {
        this._toastr.error(response.result);
      }
    })
  }

  /**
   * Modifica del preventivo
   * @private
   */
  private updatePreventivo(){
    formatDateField(this.form, ['data_scadenza']);
    this._preventivi.updatePreventivo(this.form.value).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.onSuccess();
        this.getData();
      },
      error: response => {
        this._toastr.error(response.result);
      }
    })
  }

  /**
   * Salvataggio del contratto
   * @private
   */
  private storeContratto(){
    formatDateField(this.form, ['data_scadenza', 'data_inizio_pagamento']);
    const formValue = { ...this.form.value, ...{ notes_array: this.note_flags } };
    this._preventivi.addContratto(formValue).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.onSuccess();
        this._router.navigate([`/gestione-contratti/${response.result.id}`]);
      },
      error: response => {
        this._toastr.error(response.result);
      }
    })
  }

  /**
   * Modifica del contratto
   * @private
   */
  private updateContratto(){
    formatDateField(this.form, ['data_scadenza', 'data_inizio_pagamento']);
    const formValue = { ...this.form.value, ...{ notes_array: this.note_flags } };
    this._preventivi.updateContratto(formValue).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.readonlyState = this.form.get('stato').value == 'APPROVATO';
        this.onSuccess();
        this.getData();
      },
      error: response => {
        this._toastr.error(response.result);
      }
    })
  }

  /**
   * Azioni da eseguire in caso di risposta positiva
   * @private
   */
  private onSuccess(){
    this._toastr.success('Operazione effettuata con successo');
    this._dialog.closeAll();
  }

  /**
   * Funzione di utility per gli autocomplete
   * @param item
   */
  displayFn(item: any): string {
    return item?.label;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Resetta i campi del pagamento
   * @private
   */
  private resetPaymentFields(){
    let costo_materiale_didattico = this.form.get('costo_materiale_didattico');
    let tassa_iscrizione = this.form.get('tassa_iscrizione');
    
    const reset_fields = [
      'totale_saldare',
      'costo_totale_corsi',
      'prima_rata',
      'numero_rate',
      'importo_rata',
      'rate_rimanenti',
      'acconto'
    ];
    
    if(!tassa_iscrizione.value){
      this.form.get('sconto_tassa_iscrizione').setValue(0, {emitEvent: false});
      tassa_iscrizione.setValue("1");
    }

    if(!costo_materiale_didattico.value){
      this.form.get('sconto_materiale_didattico').setValue(0, {emitEvent: false});
      costo_materiale_didattico.setValue("1");
    } 
    
    checkFormFields(this.form, [], [], reset_fields);
  }

  /**
   * Controlla se l'autocomplete dell'intestatario è valido
   */
  checkOwnerValidity() {
    let value = this.form.get('owner').value;
    return typeof value == 'string' && value != '';
  }

  /**
   * Funzione completamente inutile che serve solo a renderizzare i cambiamenti html in real time
   * @private
   */
  private simulateClick(){
    const inputElement = document.getElementById('lingua');
    inputElement.click();
    inputElement.dispatchEvent(new Event('blur'));
  }

  /**
   * Resetta i campi di ricerca per evitare che vengano salvati inutilmente in db
   * @private
   */
  private resetStudentsSearchFields(){
    this.languagesFormArray.value.forEach((item, i) => {
      this.languagesFormArray.at(i).get('students_search').reset();
    })
  }
  
  /**
   * Al cambiamento del toggle resetto i campi
   * @param i indice del FormArray
   */
  resetScontoFields(i: number){
    this.languagesFormArrayByIndex(i).get('sconto').reset();
    this.languagesFormArrayByIndex(i).get('titolo_sconto_mensile').reset();
    this.languagesFormArrayByIndex(i).get('cifra_sconto_mensile').reset();
  }

  /**
   * Funzione che permette di recuperare i costi di configurazione
   */
  private getConfigurationCost = () => {
    this._configuration.getConfigurations().pipe(takeUntil(this.destroy$)).subscribe({
      next: (response) => {
        this.basePriceMateriale = response.result.find(price => price.type === 2)?.price;
        this.basePriceTassaIscrizione = response.result.find(price => price.type === 1)?.price;
      }
    });
  }
}
