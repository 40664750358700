<div class="altezza-page px-0 mx-0">
    <div class="row px-0 mx-0" style="height: 100%;">
        <div class="col-md-2 px-0 mx-0" style="position: relative">
            <aside class="bg-color">
                <div class="margine-sup padding-logo">
                    <a routerLink="/dashboard">
                        <img alt="Logo" class="h-25px logo" src="/assets/img/logo-yeschool-1.svg" />
                    </a>
                </div>
            </aside>
        </div>
        <div class="col-md-10 margine-sup">
            <div class="section__header">
                <div class="row mb-2 d-flex align-items-center">
                    <div class="col-8">
                        <h1 class="mb-0 pb-0">{{(class_details | async)?.nome_classe}}</h1>
                    </div>
                </div>
            </div>
            <div class="section__body">
                <table class="demTable mt-5 mb-2">
                    <th class="d-none"></th>
                    <tbody>
                        <tr>
                            <td *ngIf="(class_calendar | async)?.length > 0"><b>Docente</b>:
                                {{(class_calendar|async)[0].docente?.name}}
                                {{(class_calendar|async)[0].docente?.surname}}</td>
                            <td><b>Stato</b>: {{(class_details | async)?.stato ?? 'Non definito'}}</td>
                        </tr>
                        <tr>
                            <td><b>Lingua</b>: {{(class_details | async)?.lingua_corso}}</td>
                            <td *ngIf="(class_calendar | async)?.length > 0"><b>Frequenza</b>:
                                {{(class_calendar|async)[0].frequenza ?? 'Non definita'}}</td>
                        </tr>
                        <tr>
                            <td><b>Età</b>: {{(class_details | async)?.eta}}</td>
                            <td *ngIf="(class_calendar | async)?.length > 0"><b>Monte ore</b>:
                                {{(class_calendar|async)[0].monte_ore}}</td>

                        </tr>
                        <tr>
                            <td><b>Livello</b>: {{(class_details | async)?.livello?.nome}}</td>
                            <td *ngIf="(class_calendar | async)?.length > 0"><b>Ore lezioni svolte</b>:
                                {{(class_calendar|async)[0].ore_svolte}}</td>

                        </tr>
                        <tr>
                            <td><b>Materiale didattico</b>: {{(class_details | async)?.materiale_didattico}}</td>
                            <td *ngIf="(class_calendar | async)?.length > 0"><b>Data inizio</b>:
                                {{(class_calendar|async)[0].start | date:'dd/MM/YYYY'}}</td>
                        </tr>
                        <tr>
                            <td><b>Class code</b>: {{(class_details | async)?.class_code}}</td>
                            <td *ngIf="(class_calendar | async)?.length > 0"><b>Data fine</b>: {{(class_calendar |
                                async)?.slice(-1)[0].start | date:'dd/MM/yyyy'}}</td>
                        </tr>
                        <tr>
                            <td><b>Tipo di avanzamento</b>: {{(class_details | async)?.tipo_avanzamento }}</td>
                            <td><b>Numero studenti totali</b>: {{records|async}}</td>
                        </tr>
                        <tr>
                            <td><b>Certificazione</b>: {{(class_details | async)?.certificazioni?.nome}}</td>
                        </tr>
                        <tr>
                            <td><b>Scopi del corso</b>: {{(class_details | async)?.scopi_corso}}</td>
                        </tr>



                    </tbody>
                </table>

                <nav class="mt-5">
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
                            aria-selected="true">Studenti</button>
                        <button class="nav-link" id="nav-register-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-register" type="button" role="tab" aria-controls="nav-register"
                            aria-selected="true">Registro</button>
                    </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">

                    <!-- ? TAB STUDENTI  -->
                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <div class="row my-4">
                            <div class="col-4 d-flex align-items-center">
                                <input placeholder="Cerca per nome o cognome studente" [(ngModel)]="filter"
                                    (keyup)="getData()" class="form-control form-control-lg radiusSelect">
                            </div>
                            <div class="col-6"></div>
                            <div class="col-2 d-flex justify-content-end">
                                <button type="button" class="btn color-butt my-2" data-toggle="modal"
                                    *ngIf="adminOrStaff" data-target="#exampleModalStudent">
                                    Aggiungi studente
                                </button>
                            </div>
                        </div>

                        <div *ngIf="(records | async) == 0">
                            <div class="no-cont py-4"><i class="f-16">Studenti non ancora aggiunti</i> </div>
                        </div>

                        <div *ngIf="(records | async) > 0">
                            <button class="mb-4 btn btn-outline-success" style="border: 1px solid #28a745;"
                                (click)="exportToExcel()">Esporta in Excel <img src="/assets/img/file-type-excel.svg"
                                    height="20px" width="auto" alt="">
                            </button>
                            <div class="col-3">
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item" style="border:none">
                                        <h2 class="accordion-header" id="headingOne">
                                            <span class="accordion-button" style="background: #f5f8fa73; height: 40px;"
                                                type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                aria-expanded="true" aria-controls="collapseTwo">
                                                Stato studente
                                            </span>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse show"
                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body p-1 mx-1">
                                                <ng-container>
                                                    <div class="col-12 mb-2">
                                                        <select class="form-select form-select"
                                                            aria-label="Default select example"
                                                            (change)="setStatusStudent($event)">
                                                            <option>Filtra per stato </option>
                                                            <option *ngFor="let value of status_students"
                                                                [selected]="value.selected" [value]="value.value">
                                                                {{value.label}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <table *ngIf="(records | async) > 0" mat-table class="box_super tb-bord"
                            [dataSource]="(students|async)">

                            <ng-container matColumnDef="name_surname">
                                <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga">Nome e cognome
                                </th>
                                <td mat-cell *matCellDef="let element" class="cursor-pointer"
                                    (click)="gotoFunzione(element)" onkeydown="">
                                    {{element?.studenti_anagrafica?.name}}
                                    {{element?.studenti_anagrafica?.surname}}
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="stato_studente">
                                <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga">Stato studente
                                </th>
                                <td mat-cell *matCellDef="let element" class="cursor-pointer"
                                    (click)="gotoFunzione(element)" onkeydown="">{{element?.stato_studente}}</td>
                            </ng-container>

                            <ng-container matColumnDef="data_iscrizione">
                                <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Data di
                                    inizio corso
                                    <button mat-icon-button (click)="toggleOrder('registration_date')">
                                        <mat-icon>{{ order_date === 'asc' ? 'arrow_upward' : 'arrow_downward'
                                            }}</mat-icon>
                                    </button>
                                </th>
                                <td mat-cell *matCellDef="let element" class="cursor-pointer"
                                    (click)="gotoFunzione(element)" onkeydown="">
                                    {{element.studenti_anagrafica.registration_date ?
                                    (element.studenti_anagrafica.registration_date | date:'dd/MM/YYYY') :
                                    "Non presente"}}
                                </td>

                            </ng-container>


                            <ng-container matColumnDef="certificazione">
                                <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga">Preparazione
                                    esame
                                </th>
                                <td mat-cell *matCellDef="let element" class="cursor-pointer"
                                    (click)="gotoFunzione(element)" onkeydown="">{{element?.preparazione_esame}}
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="note">
                                <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga">Note</th>
                                <td mat-cell *matCellDef="let element" class="cursor-pointer"
                                    (click)="gotoFunzione(element)" onkeydown="">{{element?.note_studente}}</td>
                            </ng-container>


                            <ng-container matColumnDef="azione">
                                <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> <span
                                        *ngIf="adminOrStaff">Azione</span></th>
                                <td mat-cell *matCellDef="let element">
                                    <button class="btn btn-outline-dark" (click)="setEdit(element)" data-toggle="modal"
                                        *ngIf="adminOrStaff" data-target="#editStudent">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                        </svg>
                                    </button>
                                    <button class="btn btn-outline-danger" data-toggle="modal"
                                        data-target="#modalDeleteClass" *ngIf="adminOrStaff"
                                        (click)="deleteModal(element)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                        </svg>
                                    </button>
                                </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>

                        <mat-paginator [length]="(records | async)" [pageSize]="pageSize"
                            [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent = handlePage($event)"
                            showFirstLastButtons>
                        </mat-paginator>


                    </div>

                    <!-- ? TAB REGISTRO  -->
                    <div class="tab-pane fade" id="nav-register" role="tabpanel" aria-labelledby="nav-register-tab">
                        <div *ngIf="(registro | async) == 0">
                            <div class="no-cont py-4"><i class="f-16">Nessuna lezione registrata</i> </div>
                        </div>

                        <ng-container *ngIf="(class_details | async) as details">
                            <ng-container *ngIf="(class_calendar | async) as calendar">
                                <app-registro-lezioni [class_id]="class_id"></app-registro-lezioni>
                            </ng-container>
                        </ng-container>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- Button trigger modal -->




<!-- ! Modal Aggiungi Docente -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="exampleModalLabel">Aggiungi Docente</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAddDocente">
                    <label class="ast" for="inputLevel"><b>Docente</b></label>
                    <select class="form-select form-select-lg" aria-label="Default select example"
                        formControlName="docente_id">
                        <option *ngFor="let value of (docente_array | async)" value="{{value.id}}">{{value.name}}
                            {{value.surname}}
                        </option>
                    </select>
                </form>
                <div class="mt-4 d-flex justify-content-between">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                    <button type="button" class="btn color-butt" (click)="addDocente()" data-bs-dismiss="modal"
                        [disabled]="!formAddDocente.valid">Salva</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ! Modal Edit Docente -->
<div class="modal fade" id="editDocentModal" tabindex="-1" aria-labelledby="editdocentLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="exampleModalLabel">Modifica Docente</h1>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formEditDocente">
                    <label class="ast" for="inputLevel"><b>Docente</b></label>
                    <select class="form-select form-select-lg" aria-label="Default select example"
                        formControlName="docente_id">
                        <option *ngFor="let value of (docente_array | async)" value="{{value.id}}">{{value.name}}
                            {{value.surname}}</option>
                    </select>
                </form>
                <div class="mt-4 d-flex justify-content-between">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                    <button type="button" class="btn color-butt" (click)="editDocente()" data-dismiss="modal"
                        [disabled]="!formEditDocente.valid">Salva</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- ! Modal Aggiungi Studente -->
<div class="modal fade" id="exampleModalStudent" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="exampleModalLabel">Aggiungi Studente</h1>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                    (click)="resetForm()"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAddStudente">
                    <label class="ast" for="inputLevel"><b>Studente</b></label>
                    <input type="text" class="form-control form-control-lg mb-3 form-control_modal search"
                        placeholder="Cerca per nome o cognome" (keyup)="searchStudents()"
                        formControlName="search_student">

                    <div class="container" *ngIf="visualizzaStudenti" class="boxautocomplete">
                        <div class="row element-autocomplete mx-0 px-0 cursor-pointer"
                            *ngFor="let item of (studentis | async)" style="padding:20px;" (click)="setStudents(item)"
                            onkeydown="">
                            <label class="cursor-pointer" style="margin-top: -4px;" for="{{item}}">
                                {{item.name}} {{item.surname}}
                            </label>
                        </div>
                    </div>

                    <div>
                        <label for="inputLevel"><b>Stato Studente</b></label>
                        <select class="form-select form-select-lg mb-3" aria-label="Default select example"
                            formControlName="stato_studente">
                            <option *ngFor="let value of array_stato_studente" value="{{value.id}}">{{value.name}}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label for="inputLevel"><b>Preparazione Esame</b></label>
                        <select class="form-select form-select-lg mb-3" aria-label="Default select example"
                            formControlName="preparazione_esame">
                            <option *ngFor="let value of array_preparazione_esame" value="{{value.id}}">{{value.name}}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label for="inputLevel"><b>Note Studente</b></label>
                        <input type="text" class="form-control form-control-lg mb-3" formControlName="note_studente">
                    </div>
                </form>
                <p><i>I campi contrassegnati <span style="color: red">*</span> sono obbligatori</i></p>
                <div class="mt-4 d-flex justify-content-between">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                    <button type="button" class="btn color-butt" (click)="addStudent()" data-dismiss="modal"
                        [disabled]="!formAddStudente.valid">Salva</button>
                </div>
            </div>

        </div>
    </div>
</div>



<!-- ! Modal Edit Studente -->
<div class="modal fade" id="editStudent" tabindex="-1" aria-labelledby="editStudentLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="exampleModalLabel">Modifica Studente</h1>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formEditStudente">
                    <div>
                        <label for="inputLevel"><b>Stato Studente</b></label>
                        <select class="form-select form-select-lg mb-3" aria-label="Default select example"
                            formControlName="stato_studente">
                            <option *ngFor="let value of array_stato_studente" [value]="value.id">{{value.name}}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label for="inputLevel"><b>Preparazione Esame</b></label>
                        <select class="form-select form-select-lg mb-3" aria-label="Default select example"
                            formControlName="preparazione_esame">
                            <option *ngFor="let value of array_preparazione_esame" [value]="value.id">{{value.name}}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label for="inputLevel"><b>Note Studente</b></label>
                        <input type="text" class="form-control form-control-lg mb-3" formControlName="note_studente">
                    </div>
                </form>
                <div class="mt-4 d-flex justify-content-between">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                    <button type="button" class="btn color-butt" (click)="editClassTable()" data-dismiss="modal"
                        [disabled]="!formEditStudente.valid">Salva</button>
                </div>
            </div>

        </div>
    </div>
</div>


<div class="modal fade bd-example-modal-sm" id="modalDeleteClass" tabindex="-1" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-sm mod-width">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">Elimina utente</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-4">
                <p class="f-16 mb-3">Eliminare definitivamente {{deleteContent?.name}} {{deleteContent?.surname}}?</p>
                <div class="d-flex justify-content-between mt-5">
                    <button class="btn color-butt" data-dismiss="modal">Annulla</button>
                    <button class="btn btn-danger" data-dismiss="modal"
                        (click)="deleteMember(deleteContent.id)">Elimina</button>
                </div>
            </div>
        </div>
    </div>
</div>