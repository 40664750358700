import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AnagraficaService } from 'src/app/services/anagraphic.service';
import { ClasseService } from 'src/app/services/class.service';
import { ConversationService } from 'src/app/services/conversation.service';
import { PermissionRoleService } from 'src/app/services/permissionRole.service';
import { LoginService } from "../../modules/auth/services/login.service";
import { getCategories, getChannels, getSidebarItems, getSubscriptions } from "../../utilities/constants";
import { checkFormFields, checkIncludedFields, dateValidator, formatDateField } from "../../utilities/form-utilities";
import { password_regex } from "../../utilities/regex";
import { checkUserCategory, isAdminOrStaff } from "../../utilities/users-utilities";
import { confirmPasswordValidator } from "../../utilities/validators/confirm-password-validator";

@Component({
  selector: 'app-info-anagrafica',
  templateUrl: './info-anagrafica.component.html',
  styleUrls: ['./info-anagrafica.component.scss']
})
export class InfoAnagraficaComponent implements OnInit, OnDestroy {
  editLinguaForm: FormGroup;
  situazioneContabile: FormGroup;
  lingue_form: FormGroup;
  addLinguaForm: FormGroup;
  currentPage = 0;
  pageSize = 10;
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  currentPage2 = 0;
  pageSize2 = 10;
  records3: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  pagamenti: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  total_lingue: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  languages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  getSituaContabile;
  variabile_button: any;
  levels;
  pageEvent: PageEvent;
  allCertifications: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  lingue: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['lingua_richiesta', 'lingua_partenza', 'certificazione', 'azione'];
  displayedColumns2: string[] = ['rata', 'importo', 'data', 'checkbox'];
  deleteMemberLingua: any;
  user_id: string;
  ruolo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  pageEvent2: PageEvent;
  array_pagamenti: any;
  classiDocente: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  conversationDocente: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  data_pagamento: any;
  rata: any;
  variabile_button2: boolean;
  hours: any;
  arrayLingue: any;
  roleType: string;

  adminOrStaff = isAdminOrStaff();
  private destroy$: Subject<void> = new Subject<void>();
  resetForm: FormGroup = new FormGroup({
    password: new FormControl(null, Validators.compose([Validators.required, Validators.pattern(password_regex())])),
    password_confirmation: new FormControl(null, Validators.compose([Validators.required]))
  }, { validators: confirmPasswordValidator })
  current_user = null;
  userForm: FormGroup = this.initUserForm();
  representativeControl: FormControl = new FormControl(null, this.autocompleteValidator);
  roles = [];
  representatives = [];
  sidebar_items = getSidebarItems();
  channels = getChannels();
  subscriptions = getSubscriptions();
  categories = getCategories();
  original_role = null;

  constructor(
    private _anagraficaService: AnagraficaService,
    private _roleService: PermissionRoleService,
    private _conversationService: ConversationService,
    private fb: FormBuilder,
    public router: Router,
    private classService: ClasseService,
    private toastr: ToastrService,
    private dateAdapter: DateAdapter<Date>,
    private _login: LoginService
  ) {
    this.dateAdapter.setLocale('it-IT');

    this.situazioneContabile = this.fb.group({
      studente_id: null,
      denaro_totale: null,
      denaro_pagato: null,
      denaro_rimanente: null,
      tipo_pagamento: null,
      rate_tot: null,
      rate_pagate: null,
      importo_rata: null,
      rate_rimanenti: null
    })

    this.lingue_form = this.fb.group({
      studente_id: null,
      filter_search: null,
      skip: null,
      take: null
    })

    this.addLinguaForm = this.fb.group({
      anagrafica_id: null,
      ling_partenza: null,
      ling_richiesta: null,
      certificazioni: null,
    })

    this.editLinguaForm = this.fb.group({
      anagrafica_id: [null],
      ling_partenza: [null],
      ling_richiesta: [null],
      certificazioni: [null],
    })
  }

  readonly checkIncludedFields = checkIncludedFields;

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    let url_split = this.router.url.split("/");
    this.user_id = this.router.url.split("/")[url_split.length - 1];

    this.getUser();

    //ritorna gli utenti che corrispondono alla stringa inserita
    this.representativeControl.valueChanges.pipe(debounceTime(1000)).subscribe({
      next: value => {
        //nel dettaglio del genitore recuperiamo i figli
        //nel dettaglio degli studenti recuperiamo i genitori e l'azienda
        let role = this.userForm.get("role").value;

        if (role == 6 || role == 7) {
          role = [5];
        }
        else if (role == 5) {
          if (this.roleType === 'role6') {
            role = [6];
          } else if (this.roleType === 'role7') {
            role = [7];
          } else {
            role = [role];
          }
        }

        let params = { search: value, role: role };
        this._anagraficaService.searchAnagrafica(params).pipe(takeUntil(this.destroy$)).subscribe(response => {
          this.representatives = response.result;
        })
      }
    })

    this.userForm.get('birth_date').valueChanges.subscribe({
      next: value => {
        let category = checkUserCategory(value);

        if (checkIncludedFields(this.userForm, 'role', [5, 6])) {
          this.userForm.get('category').setValue(category);
        }
      }
    })

    this._anagraficaService.getSituazioneContabile({ studente_id: this.user_id }).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.getSituaContabile = res.result;
      this.situazioneContabile.patchValue({
        studente_id: this.getSituaContabile?.id,
        denaro_totale: this.getSituaContabile?.denaro_totale,
        denaro_pagato: this.getSituaContabile?.denaro_pagato,
        denaro_rimanente: this.getSituaContabile?.denaro_rimanente,
        tipo_pagamento: this.getSituaContabile?.tipo_pagamento,
        rate_tot: this.getSituaContabile?.rate_tot,
        rate_pagate: this.getSituaContabile?.rate_pagate,
        importo_rata: this.getSituaContabile?.importo_rata,
        rate_rimanenti: this.getSituaContabile?.rate_rimanenti
      })
    })

    this.getLanguages();
    this.getRoles();
    this.getLevel();
    this.getLingueTable();
    this.getPaymentTable();
    this.getClassiDocente();
    this.getConversationDocente();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Riceve dal focus dell'autocomplete del componente figlio il roleType
   * @param roleType 
   */
  handleAutocompleteClick(roleType: string) {
    console.log("L'autocomplete è stato cliccato nel componente figlio!", roleType);
    this.roleType = roleType;
    this.representatives = [];
  }

  getAllCertifications(event) {
    let id: number = this.arrayLingue.find(el => el.nome_lingua === event.target.value).id

    this.classService.getAllCertifications({ lingua_id: id }).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.allCertifications.next(res.result);
      let obj = { id: "Nessuna", nome: "Nessuna" };
      this.allCertifications.value.unshift(obj);
    })
  }

  getLevel() {
    this.classService.getLevel().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.result) {
        this.levels = response.result
        this.addLinguaForm.patchValue({ ling_richiesta: response.result[0].nome_lingua });
      }
    });
  }

  getLanguages() {
    this.classService.getLanguage().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.languages.next(res.result);
      this.arrayLingue = res.result;
      this.classService.getAllCertifications({ lingua_id: this.arrayLingue[0].id }).pipe(takeUntil(this.destroy$)).subscribe(res => {
        this.allCertifications.next(res.result);
        let obj = { id: "Nessuna", nome: "Nessuna" };
        this.allCertifications.value.unshift(obj);
      });
    })
  }

  funzionew1() {
    this.addLinguaForm.patchValue({ ling_richiesta: this.arrayLingue[0].nome_lingua });
    this.addLinguaForm.patchValue({ ling_partenza: this.levels[0].nome });
    this.addLinguaForm.patchValue({ certificazioni: this.allCertifications.value[0].nome });
  }

  goTo(id) {
    this.router.navigate(['class-details/' + id]);
  }

  goToConversation(id) {
    this.router.navigate(['conversation-details/' + id]);
  }

  getClassiDocente() {
    this.classService.getClassiDocente({ docente_id: this.user_id }).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.classiDocente.next(res.result);
    })
  }

  getConversationDocente() {
    this._conversationService.getConversationDocente({ docente_id: this.user_id }).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.conversationDocente.next(res.result);
    })
  }

  handlePage2(e: any) {
    this.currentPage2 = e.pageIndex;
    this.pageSize2 = e.pageSize;
    this.getPaymentTable();
    return e;
  }

  //GET LINGUE//
  getLingueTable() {
    this.lingue_form.patchValue({ studente_id: this.user_id });
    this.lingue_form.patchValue({ skip: this.currentPage * this.pageSize });
    this.lingue_form.patchValue({ take: this.pageSize });
    this._anagraficaService.getLingue(this.lingue_form.value).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.total_lingue.next(res.total);
      this.lingue.next(res.result);
    })
  }

  getPaymentTable() {
    this._anagraficaService.getAllPayment({ studente_id: this.user_id, skip: this.currentPage2 * this.pageSize2, take: this.pageSize2 }).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.array_pagamenti = res.result;
      this.pagamenti.next(res.result);
      this.records3.next(res.total);
    })
  }

  //EDIT LINGUE//
  editLingua() {
    this._anagraficaService.editLingue(this.editLinguaForm.value).pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res.status) {
        this.toastr.success("Lingua modificata con successo");
        this.editLinguaForm.reset();
        this.getLingueTable();
      }
    }, error => {
      this.toastr.error("Errore durante la modifica della lingua")
    });
  }

  setPayment(rata) {
    let id = rata.id;
    let data_pagamento = this.data_pagamento;
    let value = true
    this._anagraficaService.editPayment({ value: value, id: id, data_pagamento: this.data_pagamento }).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.toastr.success("Pagamento modificato con successo");
      this.getPaymentTable();
    })
  }

  //VEDO SE CHECCATO//
  checked(ID) {
    let element;
    for (let i = 0; i < this.array_pagamenti.length; i++) {
      if (this.array_pagamenti[i].id == ID) {
        element = this.array_pagamenti[i].pagato;
        if (element == false || element == 'Non saldato') {
          this.variabile_button = "Non saldato";
          this.variabile_button2 = false;
          return false;
        } else {
          this.variabile_button = "Saldato";
          this.variabile_button2 = true;
          return true;
        }
      }
    }
  }

  setRata(element) {
    this.rata = element;
  }

  //DELETE LINGUE//
  deleteLingua(id) {
    this._anagraficaService.deleteLingue({ id: id }).pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res.status) {
        this.toastr.success("Lingua eliminata con successo");
        this.editLinguaForm.reset();
        this.getLingueTable();
      }
    }, error => {
      this.toastr.error("Errore durante l'eliminazione della lingua")
    });
  }

  //STORE LINGUE//
  storeLingue() {
    this.addLinguaForm.patchValue({ anagrafica_id: this.user_id });
    this._anagraficaService.storeLingue(this.addLinguaForm.value).pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res.status) {
        this.toastr.success("Lingua aggiunta con successo");
        this.addLinguaForm.reset();
        this.getLingueTable();
      }
    }, error => {
      this.toastr.error("Errore durante l'inserimento lingua")
    });
  }

  getRoles() {
    this._roleService.getRoles().pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.roles = response.result.splice(1, 6).map(item => ({
          label: item.display_name,
          value: item.id
        }));
      }
    })
  }

  setMemberLingua(member: any) {

    this.editLinguaForm.reset();

    let id: number = this.arrayLingue.find(el => el.nome_lingua === member.ling_richiesta).id

    this.classService.getAllCertifications({ lingua_id: id }).pipe(takeUntil(this.destroy$)).subscribe(res => {

      if (res) {

        this.allCertifications.next(res.result);
        let obj = { id: "Nessuna", nome: "Nessuna" };
        this.allCertifications.value.unshift(obj);

        let certificationValue = member.certificazioni.trim();

        this.editLinguaForm.patchValue({
          anagrafica_id: member.id,
          ling_partenza: member.ling_partenza,
          ling_richiesta: member.ling_richiesta,
          certificazioni: certificationValue
        })
      }

    });

  }

  setMemberDeleteLingua(member: any) {
    this.deleteMemberLingua = member;
  }

  /**
   * Reset della password da parte dello staff
   */
  onResetPassword() {
    this._login.resetPassword(this.current_user, this.resetForm.value).subscribe({
      next: response => {
        this.resetForm.reset();
        this.toastr.success(response.message);
      }
    })
  }

  /**
   * Inizializzazione del form per la creazione di un utente
   * @private
   */
  private initUserForm() {
    return new FormGroup({
      name: new FormControl(null, Validators.required),
      surname: new FormControl(null, Validators.required),
      birth_date: new FormControl('', [dateValidator]),
      email: new FormControl(null),
      iscritto: new FormControl(null),
      registration_date: new FormControl(null),
      password: new FormControl(null),
      password_confirmation: new FormControl(null),
      phone: new FormControl(null),
      address: new FormControl(null),
      city: new FormControl(null),
      cap: new FormControl(null),
      color_bg: new FormControl(null),
      fiscal_code: new FormControl(null),
      pec: new FormControl(null),
      channel: new FormControl(null),
      p_iva: new FormControl(null),
      unique_code: new FormControl(null),
      role: new FormControl(null),
      social: new FormControl(null),
      privacy: new FormControl(null),
      email_promozionali: new FormControl(null),
      consenso_immagini: new FormControl(null),
      category: new FormControl(null)
    });
  }

  /**
   * Applica i validatori ai FormControl
   * @private
   */
  private checkFormFields() {
    let required_fields = [];
    let unrequired_fields = [];

    if (checkIncludedFields(this.userForm, 'role', [2, 3, 4])) {
      required_fields = required_fields.concat(['email']);
    }
    else {
      unrequired_fields = unrequired_fields.concat(['email', 'color_bg']);
    }

    checkFormFields(this.userForm, required_fields, unrequired_fields, []);
  }

  /**
   * Ritorna i dati dell'utenza
   * @private
   */
  private getUser() {
    this._anagraficaService.getUser(this.user_id).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.current_user = response.result;
        this.userForm.patchValue(response.result);
        this.original_role = response.result.role;
        this.checkFormFields();
      }
    })
  }

  /**
   * Assegnazione referente/figlio
   */
  setRepresentative() {
    let params = null;

    if (checkIncludedFields(this.userForm, 'role', [6, 7])) {
      params = { studente_id: this.representativeControl.value.value, referente_id: this.current_user.id }
    }
    else if (checkIncludedFields(this.userForm, 'role', [5])) {
      params = { studente_id: this.current_user.id, referente_id: this.representativeControl.value.value }
    }

    this._anagraficaService.setRepresentative(params).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.representativeControl.reset();
        this.toastr.success(response.message);
        this.getUser();
      }
    })
  }

  /**
   * Rimozione referente/studente
   */
  deleteRepresentative(id) {
    let params = null;

    if (checkIncludedFields(this.userForm, 'role', [6])) {
      params = { studente_id: id, referente_id: this.current_user.id }
    }
    else if (checkIncludedFields(this.userForm, 'role', [5])) {
      params = { studente_id: this.current_user.id, referente_id: id }
    }

    this._anagraficaService.deleteRepresentative(params).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.toastr.success(response.message);
        this.getUser();
      }
    })
  }

  /**
   * Controlla se l'elemento inserito è un oggetto o una stringa
   * @param formControl
   */
  autocompleteValidator(formControl: FormControl): ValidationErrors | null {
    if (!formControl.value || typeof formControl.value == 'string') {
      return { invalid: true };
    }

    return null;
  }

  /**
   * Modifica di un'utenza
   */
  updateAnagraficaMember() {
    formatDateField(this.userForm, ['registration_date']);

    const formValue = {
      ...this.userForm.value,
      name: this.userForm.get('name').value.toUpperCase(),
      surname: this.userForm.get('surname').value.toUpperCase(),
      address: this.userForm.get('address')?.value?.toUpperCase(),
      city: this.userForm.get('city')?.value?.toUpperCase(),
      fiscal_code: this.userForm.get('fiscal_code')?.value?.toUpperCase()
    };
    this._anagraficaService.editUser(this.user_id, formValue).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.getUser();
        this.toastr.success(response.message);
        this.userForm.patchValue(response.result);
      },
      error: response => this.toastr.error(response.result)
    })
  }

  /**
   * Al click su un elemento della sidebar
   * @param role
   */
  filterRole(role: any) {
    this.router.navigate(["/anagrafica", role])
  }
}

