<label *ngIf="type != 'checkbox' && type != 'toggle' && label" [for]="control" [ngClass]="{'ast': isRequiredField()}">
    {{label}}
    <i class="fa fa-info-circle" [matTooltip]="tooltipText" matTooltipPosition="above" *ngIf="tooltipText"></i>
</label>

<form *ngIf="form" [formGroup]="form">
    <ng-container [ngTemplateOutlet]="inputs"></ng-container>
</form>

<ng-container *ngIf="formcontrol" [ngTemplateOutlet]="inputs"></ng-container>

<!-- INPUTS -->
<ng-template #inputs>
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'input'" [ngTemplateOutlet]="input"></ng-container>
        <ng-container *ngSwitchCase="'textarea'" [ngTemplateOutlet]="textarea"></ng-container>
        <ng-container *ngSwitchCase="'date'" [ngTemplateOutlet]="datepicker"></ng-container>
        <ng-container *ngSwitchCase="'rangedate'" [ngTemplateOutlet]="rangedatepicker"></ng-container>
        <ng-container *ngSwitchCase="'select'" [ngTemplateOutlet]="select"></ng-container>
        <ng-container *ngSwitchCase="'autocomplete'" [ngTemplateOutlet]="autocomplete"></ng-container>
        <ng-container *ngSwitchCase="'checkbox'" [ngTemplateOutlet]="checkbox"></ng-container>
        <ng-container *ngSwitchCase="'toggle'" [ngTemplateOutlet]="toggle"></ng-container>
    </ng-container>
</ng-template>

<!-- INPUT -->
<ng-template #input>
    <ng-container *ngIf="form" [formGroup]="form">
        <input *ngIf="!formArray" 
            [class]="'form-control ' + class"
            [type]="inputType" 
            [formControlName]="control"
            [id]="control"
            [placeholder]="placeholder ?? ''"
            [attr.disabled]="readonly ? true : null"
            [min]="range?.min ? range.min : -99999999999999" [max]="range?.max ? range.max : 99999999999999"
            [ngClass]="{'is-invalid': form.controls[control].invalid && form.controls[control].touched}"
            [style.text-transform]="uppercase ? 'uppercase' : 'none'">

            <ng-container *ngIf="form.controls[control] instanceof AbstractControl">
                <div *ngIf="form.controls[control].hasError('invalidDateFormat')">
                    {{ errorMessageDateFormat }}
                </div>
                <div *ngIf="form.controls[control].hasError('invalidDate')">
                    {{ errorMessageInvalidDate }}
                </div>
            </ng-container>

        <ng-container *ngIf="formArray" [formArrayName]="formArrayName">
            <div [formGroupName]="formGroupName">
                <input 
                    class="form-control" 
                    [type]="inputType" 
                    [formControlName]="control"
                    [min]="range?.min ? range.min : -99999999999999" [max]="range?.max ? range.max : 99999999999999"
                    [id]="control"
                    [placeholder]="placeholder ?? ''"
                    [style.text-transform]="uppercase ? 'uppercase' : 'none'">
            </div>
        </ng-container>
    </ng-container>
    
    <input *ngIf="formcontrol" class="form-control" [type]="inputType" [formControl]="formcontrol" [id]="control"
        [placeholder]="placeholder ?? ''"
        [ngClass]="{'is-invalid': form.controls[control].invalid && form.controls[control].touched}"
        [style.text-transform]="uppercase ? 'uppercase' : 'none'">
</ng-template>

<!-- TEXTAREA -->
<ng-template #textarea>
    <ng-container *ngIf="form" [formGroup]="form">
        <textarea rows="4" class="form-control"
            [formControlName]="control" 
            [id]="control"
            [placeholder]="placeholder ?? ''"
            [ngClass]="{'is-invalid': form.controls[control].invalid && form.controls[control].touched}">
        </textarea>
    </ng-container>

    <textarea *ngIf="formcontrol" rows="4" class="form-control"
        [formControl]="formcontrol"
        [id]="control"
        [placeholder]="placeholder ?? ''"
        [ngClass]="{'is-invalid': form.controls[control].invalid && form.controls[control].touched}">
    </textarea>
</ng-template>

<!-- DATEPICKER -->
<ng-template #datepicker>
    <ng-container *ngIf="form" [formGroup]="form">
        <mat-form-field class="w-100 input-border">
            <input matInput [matDatepickerFilter]="datepickerFilter" [formControlName]="control" [matDatepicker]="date" readonly>
            <mat-icon matDatepickerToggleIcon (click)="clearDate()" onKeyDown="">clear</mat-icon>
            <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
            <mat-datepicker #date></mat-datepicker>
        </mat-form-field>
    </ng-container>

    <mat-form-field *ngIf="formcontrol" class="w-100 input-border">
        <input matInput [formControl]="formcontrol" [matDatepicker]="date" readonly>
        <mat-icon matDatepickerToggleIcon (click)="clearDate()" onKeyDown="">clear</mat-icon>
        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
    </mat-form-field>
</ng-template>

<!-- RANGE DATEPICKER -->
<ng-template #rangedatepicker>
    <mat-form-field class="w-100 input-border" [formGroup]="form">
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [formControlName]="controls[0]" readonly>
            <input matEndDate [formControlName]="controls[1]" readonly>
        </mat-date-range-input>
        <mat-icon matDatepickerToggleIcon (click)="clearDate()" onKeyDown="">clear</mat-icon>
        <mat-datepicker-toggle class="range-datepicker" matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
</ng-template>

<!-- SELECT -->
<ng-template #select>
    <ng-container *ngIf="form" [formGroup]="form">
        <select *ngIf="!formArray" class="form-select" [formControlName]="control" [id]="control"
            [ngClass]="{'select-disabled': readonly}">
            <option *ngFor="let item of values" value="{{item.value}}">
                {{item.label}}
            </option>
        </select>
        
        <ng-container *ngIf="formArray">
            <ng-container [formArrayName]="formArrayName">
                <ng-container [formGroupName]="formGroupName">
                    <select class="form-select" formControlName="lingua">
                        <option *ngFor="let item of values" value="{{item.value}}">
                            {{item.label}}
                        </option>
                    </select>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

    <select *ngIf="formcontrol" class="form-select" [formControl]="formcontrol" [id]="control"
        [ngClass]="{'select-disabled': readonly}">
        <option *ngFor="let item of values" value="{{item.value}}">
            {{item.label}}
        </option>
    </select>
</ng-template>

<!-- AUTOCOMPLETE -->
<ng-template #autocomplete>
    <ng-container *ngIf="form" [formGroup]="form">
        <mat-form-field *ngIf="!formArray" class="w-100 input-border">
            <input type="text" matInput [formControlName]="control" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onValueChange($event.option.value)">
                <ng-container *ngFor="let item of values">
                    <mat-option  [value]="item">{{item.label}}</mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>

        <ng-container *ngIf="formArray">
            <ng-container [formArrayName]="formArrayName">
                <ng-container [formGroupName]="formGroupName">
                    <mat-form-field class="w-100 input-border">
                        <input type="text" matInput [formControlName]="control" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onValueChange($event.option.value)">
                            <ng-container *ngFor="let item of values">
                                <mat-option  [value]="item">{{item.label}}</mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

    <mat-form-field *ngIf="formcontrol" class="w-100 input-border">
        <input type="text" matInput [formControl]="formcontrol" [matAutocomplete]="auto" (focus)="shouldShowOption()">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onValueChange($event.option.value)">
            <ng-container *ngFor="let item of values">
                <mat-option [value]="item">{{item.label}}</mat-option>
            </ng-container>
        </mat-autocomplete>
    </mat-form-field>
</ng-template>

<!-- CHECKBOX -->
<ng-template #checkbox>
    <ng-container *ngIf="form" [formGroup]="form">
        <mat-checkbox color="primary" [formControlName]="control">
            {{label}}
        </mat-checkbox>
    </ng-container>

    <mat-checkbox *ngIf="formcontrol" color="primary" [formControl]="formcontrol">
        {{label}}
    </mat-checkbox>
</ng-template>

<!--TOGGLE-->
<ng-template #toggle>
    <ng-container *ngIf="form" [formGroup]="form">
        <mat-slide-toggle *ngIf="!formArray" color="primary" [formControlName]="control">
            {{label}}
        </mat-slide-toggle>

        <ng-container *ngIf="formArray" [formArrayName]="formArrayName">
            <div [formGroupName]="formGroupName">
                <mat-slide-toggle color="primary" [formControlName]="control">
                    {{label}}
                </mat-slide-toggle>
            </div>
        </ng-container>
    </ng-container>

    <mat-slide-toggle *ngIf="formcontrol" color="primary" [formControl]="formcontrol">
        {{label}}
    </mat-slide-toggle>
</ng-template>