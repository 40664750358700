import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import { FormatoItalianoPipe } from '../formato-italiano.pipe';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [FormatoItalianoPipe]
})
export class InputComponent {
  @Input() form: FormGroup = null;
  @Input() formArray: FormArray = null;
  @Input() formArrayName: string = null;
  @Input() formGroupName: number = null;
  @Input() formcontrol: FormControl = null;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() inputType: string = 'text';
  @Input() control: string;
  @Input() controls: Array<string>;
  @Input() label: string;
  @Input() values: Array<any>;
  @Input() readonly: boolean = false;
  @Input() roleType: string;
  @Input() class: string = '';
  @Input() datepickerFilter;
  @Input() range = null;
  @Input() uppercase: boolean = false;
  @Input() errorMessageDateFormat: string = 'Inserisci la data nel formato GG/MM/AAAA.';
  @Input() errorMessageInvalidDate: string = 'Data non valida. Controlla i giorni, i mesi e gli anni.';
  @Input() tooltipText: string;

  @Output() autocompleteClick = new EventEmitter<string>();
  @Output() valueChange = new EventEmitter<any>();

  constructor() {
  }

  /**
   * Funzione di utility per controllare se un campo è required
   */
  isRequiredField(): boolean {
    let control = this.FormControl;
  
    if (!control?.validator) {
      return false;
    }
  
    if (control instanceof AbstractControl) {
      if (control.hasError('invalidDateFormat') || control.hasError('invalidDate') || control.hasError('customValidator')) {
        return false; 
      }
    }
  
    const validator = control.validator({} as any);
    return validator?.required;
  }

  /**
   * Alla selezione dell'elemento di un autocomplete mostriamo la label
   * @param item
   */
  displayFn(item: any): string {
    return item?.label;
  }

  /**
   * Gestione del roletype
   * @returns
   */
  shouldShowOption() {
    this.autocompleteClick.emit(this.roleType);
    return this.roleType;
  }

  /**
   * Funzione per resettare un input di tipo data
   */
  clearDate() {
    if (this.controls) {
      this.controls.forEach(control => {
        if (this.form.get(control).value) {
          this.form.get(control).reset(null, {emitEvent: false});
        }
      })
    } else {
      let formControl = this.FormControl;

      if (formControl.value) {
        formControl.reset(null, {emitEvent: false});
      }
    }
  }

  /**
   * Getter del control in base alle diverse casistiche
   * @constructor
   */
  get FormControl() {
    let formControl: AbstractControl;

    if(!this.formArray) {
      formControl = this.form ? this.form.get(this.control) : this.formcontrol;
    }
    else {
      formControl = (this.form.get(this.formArrayName) as FormArray).at(this.formGroupName).get(this.control);
    }

    return formControl;
  }

  /**
   * Gestisce l'evento che seleziona l'utente
   * @param event 
   */
  onValueChange(event: any) {
    this.valueChange.emit(event);
  }
}

