<div class="altezza-page px-0 mx-0">
    <div class="row px-0 mx-0" style="height: 100%;">
        <div class="col-md-2 px-0 mx-0" style="position: relative">
            <aside class="bg-color">
                <div class="margine-sup padding-logo">
                    <a routerLink="/dashboard">
                        <img alt="Logo" class="h-25px logo" src="/assets/img/logo-yeschool-1.svg" />
                    </a>
                </div>
            </aside>
        </div>
        <div class="col-md-10 margine-sup">
            <div class="section__header">
                <div class="row mb-2 d-flex align-items-center">
                    <div class="col-8">
                        <h1 class="mb-0 pb-0">{{(class_details | async)?.titolo}}</h1>
                    </div>
                </div>
            </div>
            <div class="section__body">

                <div class="my-5">
                    <div class="row">
                        <div class="col d-flex align-items-center">
                            <span class="col-title-stats">Docente:</span>
                            <span *ngIf="docente|async" class="col-data-text me-2">
                                {{(docente|async)?.name}} {{(docente|async)?.surname}}
                            </span>
                            <button *ngIf="(docente|async) && adminOrStaff" (click)="setDocente()"
                                class="btn btn-outline-dark btn-edit me-1" data-toggle="modal"
                                data-target="#editDocentModal" onkeydown="">
                                <i class="fas fa-pen icon-edit"></i>
                            </button>

                            <button *ngIf="!(docente| async)?.name" class="btn btn-outline-primary btn-add-user"
                                data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i class="fas fa-user-plus add-icon"></i>
                            </button>

                        </div>
                        <div class="col d-flex align-items-center">
                            <span class="col-title-stats">Lingua:</span>
                            <span class="col-data-text">{{(class_details | async)?.lingua}}</span>
                        </div>
                        <div class="col d-flex align-items-center">
                            <span class="col-title-stats">Livello:</span>
                            <span class="col-data-text">{{(class_details | async)?.livello.nome}}</span>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <span class="col-title-stats">Totale studenti:</span>
                            <span class="col-data-text">{{records|async}}</span>
                        </div>
                    </div>
                </div>

                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
                            aria-selected="true">Studenti</button>
                        <button class="nav-link" id="nav-register-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-register" type="button" role="tab" aria-controls="nav-register"
                            aria-selected="true">Registro</button>
                    </div>
                </nav>

                <div class="tab-content" id="nav-tabContent">

                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <div class="row my-4">
                            <div *ngIf="(records | async) == 0" class="col-4"></div>
                            <div class="col-4 d-flex align-items-center" *ngIf="(records | async) > 0">
                                <input placeholder="Cerca per nome" [(ngModel)]="filter" (keyup)="getData()"
                                    class="form-control form-control-lg radiusSelect">
                            </div>
                            <div class="col-6"></div>
                            <div class="col-2 d-flex justify-content-end">
                                <button type="button" class="btn color-butt my-2" data-toggle="modal"
                                    *ngIf="adminOrStaff" data-target="#exampleModalStudent">
                                    Aggiungi studente
                                </button>
                            </div>
                        </div>

                        <div *ngIf="(records | async) == 0" class="mb-5">
                            <div class="no-cont py-4"><i class="f-16">Nessun risultato riscontrato</i> </div>
                        </div>

                        <div *ngIf="(records|async)>0">
                            <table mat-table class="box_super tb-bord" [dataSource]="(students|async)">

                                <!-- Position Column -->
                                <ng-container matColumnDef="nome">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Nome e
                                        Cognome
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="cursor-pointer"
                                        (click)="gotoFunzione(element)" onkeydown=""> {{element?.studente?.name}}
                                        {{element?.studente?.surname}} </td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="email">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Email
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="cursor-pointer"
                                        (click)="gotoFunzione(element)" onkeydown=""> {{element?.studente?.email}} </td>
                                </ng-container>

                                <!-- Weight Column -->
                                <ng-container matColumnDef="telefono">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga">Telefono
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="cursor-pointer"
                                        (click)="gotoFunzione(element)" onkeydown=""> {{element?.studente?.phone}} </td>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="ore_acquistate">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Ore
                                        Acquistate
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="cursor-pointer"
                                        (click)="gotoFunzione(element)" onkeydown=""> {{element?.ore_acquistate}} </td>
                                </ng-container>

                                <ng-container matColumnDef="ore_svolte">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Ore
                                        svolte </th>
                                    <td mat-cell *matCellDef="let element" class="cursor-pointer"
                                        (click)="gotoFunzione(element)" onkeydown=""> {{element?.ore_svolte}} </td>
                                </ng-container>

                                <ng-container matColumnDef="data_iscrizione">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga">Data
                                        iscrizione
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="cursor-pointer"
                                        (click)="gotoFunzione(element)" onkeydown=""> {{element?.data_iscrizione|
                                        date:'dd/MM/YYYY'}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="azione">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"></th>
                                    <td mat-cell *matCellDef="let element" class="cursor-pointer">
                                        <button class="btn btn-outline-dark" (click)="setEdit(element)"
                                            data-toggle="modal" data-target="#editStudent" *ngIf="adminOrStaff">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                            </svg>
                                        </button>
                                        <button class="btn btn-outline-danger" data-toggle="modal"
                                            (click)="setDeleteElement(element)" data-target="#modalDeleteClass"
                                            *ngIf="adminOrStaff">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                            </svg>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>

                            <mat-paginator [length]="(records | async)" [pageSize]="pageSize"
                                [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent = handlePage($event)"
                                showFirstLastButtons>
                            </mat-paginator>
                        </div>
                    </div>
                    <!--TAB REGISTRO-->
                    <div class="tab-pane fade" id="nav-register" role="tabpanel" aria-labelledby="nav-register-tab">
                        <cdk-accordion class="example-accordion">
                            <cdk-accordion-item *ngFor="let item of (registro|async); let index = index;"
                                #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button"
                                tabindex="0" [attr.id]="'accordion-header-' + index"
                                [attr.aria-expanded]="accordionItem.expanded"
                                [attr.aria-controls]="'accordion-body-' + index" onkeydown="">
                                <div class="example-accordion-item-header" (click)="accordionItem.toggle()"
                                    (click)="setItemRegistro(item)" onkeydown="">
                                    Lezione di giorno: {{ item.giorno | date:'dd/MM/YYYY' }}
                                    <span class="example-accordion-item-description">
                                        {{ accordionItem.expanded ? 'Chiudi dettaglio' : 'Accedi al dettaglio' }}
                                    </span>
                                </div>
                                <div class="example-accordion-item-body"
                                    [style.display]="accordionItem.expanded ? '' : 'none'"
                                    [attr.id]="'accordion-body-' + index"
                                    [attr.aria-labelledby]="'accordion-header-' + index">
                                    <div class="d-flex flex-column">
                                        <div class="d-flex-column align-items-center ">
                                            <h3>Numero ore:
                                                {{item.durata}}</h3>
                                            <h3>Docente:
                                                {{item.docente}}</h3>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <label for="check-presenza">Lezione eseguita</label>
                                            <input id="check-presenza" type="checkbox" [checked]="item.presenza"
                                                (click)="editRegistro($event)" onkeydown="">
                                        </div>
                                        <div>
                                            <button class="btn btn-primary" type="button" (click)="goTo(item)">Vai al
                                                registro</button>
                                        </div>
                                    </div>
                                </div>
                            </cdk-accordion-item>
                        </cdk-accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ! Modal Aggiungi Docente -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="exampleModalLabel">Aggiungi Docente</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAddDocente">
                    <label for="inputLevel"><b>Docente</b></label>
                    <select class="form-select form-select-lg" aria-label="Default select example"
                        formControlName="docente_id">
                        <option *ngFor="let value of (docente_array | async)" value="{{value.id}}">{{value.name}}
                            {{value.surname}}
                        </option>
                    </select>
                </form>
                <div class="mt-4 d-flex justify-content-between">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                    <button type="button" class="btn color-butt" (click)="addDocente()"
                        data-bs-dismiss="modal">Salva</button>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- ! Modal Edit Docente -->
<div class="modal fade" id="editDocentModal" tabindex="-1" aria-labelledby="editdocentLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="exampleModalLabel">Modifica Docente</h1>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formEditDocente">
                    <label for="inputLevel"><b>Docente</b></label>
                    <select class="form-select form-select-lg" aria-label="Default select example"
                        formControlName="docente_id">
                        <option *ngFor="let value of (docente_array | async)" value="{{value.id}}">{{value.name}}
                            {{value.surname}}</option>
                    </select>
                </form>
                <div class="mt-4 d-flex justify-content-between">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                    <button type="button" class="btn color-butt" (click)="editDocente()"
                        data-dismiss="modal">Salva</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- ! Modal Aggiungi Studente -->
<div class="modal fade" id="exampleModalStudent" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="exampleModalLabel">Aggiungi Studente</h1>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAddStudente">
                    <label class="ast" for="inputLevel"><b>Studente</b></label>
                    <input type="text" class="form-control form-control-lg mb-3 form-control_modal search"
                        placeholder="Cerca per nome o cognome" (keyup)="searchStudents()"
                        formControlName="search_student">

                    <div class="container" *ngIf="visualizzaStudenti" class="boxautocomplete">
                        <div class="row element-autocomplete mx-0 px-0 cursor-pointer"
                            *ngFor="let item of (studentis | async)" style="padding:20px;" (click)="setStudents(item)"
                            onkeydown="">
                            <label class="cursor-pointer" style="margin-top: -4px;" for="{{item}}">
                                {{item.name}} {{item.surname}}
                            </label>
                        </div>
                    </div>

                    <div>
                        <label for="inputLevel"><b>Ore Acquistate</b></label>
                        <input type="number" class="form-control form-control-lg mb-3" formControlName="ore_acquistate">
                    </div>
                    <div>
                        <label for="inputLevel"><b>Ore Svolte</b></label>
                        <input type="number" class="form-control form-control-lg mb-3" formControlName="ore_svolte">
                    </div>
                    <div class="form-group">
                        <label for="inputDate"><b>Data Iscrizione</b></label>
                        <mat-form-field appearance="outline" class="col-12 px-0">
                            <input matInput [matDatepicker]="add_b_date" formControlName="data_iscrizione" readonly>
                            <mat-datepicker-toggle matSuffix [for]="add_b_date">
                            </mat-datepicker-toggle>
                            <mat-datepicker #add_b_date></mat-datepicker>
                        </mat-form-field>
                    </div>
                </form>
                <p><i>I campi contrassegnati <span style="color: red">*</span> sono obbligatori</i></p>
                <div class="mt-4 d-flex justify-content-between">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                    <button type="button" class="btn color-butt" (click)="addStudent()" data-dismiss="modal"
                        [disabled]="!formAddStudente.valid">Salva</button>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- ! Modal Edit Studente -->
<div class="modal fade" id="editStudent" tabindex="-1" aria-labelledby="editStudentLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="exampleModalLabel">Modifica Studente</h1>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formEditStudente">
                    <div>
                        <label for="inputLevel"><b>Ore Acquistate</b></label>
                        <input type="number" class="form-control form-control-lg mb-3" formControlName="ore_acquistate">
                    </div>
                    <div>
                        <label for="inputLevel"><b>Ore Svolte</b></label>
                        <input type="number" class="form-control form-control-lg mb-3" formControlName="ore_svolte">
                    </div>
                    <div class="form-group">
                        <label for="inputDate"><b>Data Iscrizione</b></label>
                        <mat-form-field appearance="outline" class="col-12 px-0">
                            <input matInput readonly [matDatepicker]="f_date" formControlName="data_iscrizione">
                            <mat-datepicker-toggle matSuffix [for]="f_date">
                            </mat-datepicker-toggle>
                            <mat-datepicker #f_date></mat-datepicker>
                        </mat-form-field>
                    </div>
                </form>
                <div class="mt-4 d-flex justify-content-between">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                    <button type="button" class="btn color-butt" (click)="editStudente()" data-dismiss="modal"
                        [disabled]="!formEditStudente.valid">Salva</button>
                </div>
            </div>

        </div>
    </div>
</div>


<!-- ! Modal Delete Member  -->
<div class="modal fade bd-example-modal-sm" id="modalDeleteClass" tabindex="-1" aria-labelledby="deleteClassLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-sm mod-width">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">Elimina Studente</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-4">
                <p class="f-16 mb-3">Eliminare definitivamente {{deleteContent?.studente?.name}}
                    {{deleteContent?.studente?.surname}}</p>
                <div class="d-flex justify-content-between mt-5">
                    <button class="btn color-butt" data-dismiss="modal">Annulla</button>
                    <button class="btn btn-danger" data-dismiss="modal"
                        (click)="deleteStudent(deleteContent.id)">Elimina</button>
                </div>
            </div>
        </div>
    </div>
</div>