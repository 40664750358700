const sidebar_items = [
    { label: 'Docenti', value: 4, icon: './assets/media/icons/duotune/general/gen019.svg' },
    { label: 'Studenti', value: 5, icon: './assets/media/icons/duotune/art/art002.svg' },
    { label: 'Genitori', value: 6, icon: './assets/media/icons/duotune/art/art002.svg' },
    { label: 'Aziende', value: 7, icon: './assets/media/icons/duotune/art/art002.svg' },
    { label: 'Staff', value: 3, icon: './assets/media/icons/duotune/communication/com006.svg' },
    { label: 'Admin', value: 2, icon: './assets/media/icons/duotune/communication/com006.svg' }
];

const channels = [
    { label: 'Internet', value: '1' },
    { label: 'Facebook', value: '2' },
    { label: 'Instagram', value: '3' },
    { label: 'Insegna', value: '4' },
    { label: 'Passaparola', value: '5' },
    { label: 'Amico', value: '6' },
    { label: 'Ex studenti', value: '7' },
    { label: 'Eventi', value: '8' },
    { label: 'Yesclub', value: '9' },
    { label: 'Email', value: '10' }
];

const subscriptions = [
    { label: 'Iscritto', value: 1 },
    { label: 'Interessato', value: 0 },
];

const categories = [
    { label: 'Adults', value: 'Adults' },
    { label: 'Kids', value: 'Kids' },
    { label: 'Teens', value: 'Teens' }
];

const payment_typologies = [
    { label: 'Unica soluzione', value: 1 }, // Ex Classico
    { label: 'Preventivo con acconto', value: 2 }, // Ex Alternativo
    { label: 'Preventivo con acconto e prima rata', value: 3 }, // Ex Personalizzato
    { label: 'Preventivo con acconto manuale', value: 4 }
];

const stati_preventivo = [
    { value: "IN CORSO", label: "IN CORSO" },
    { value: "DA APPROVARE", label: "DA APPROVARE" },
    { value: "APPROVATO", label: "APPROVATO" }
]

const note_flags = [
    { id: 1, name: 'note_one', label: 'Ai fini del raggiungimento del livello, è obbligatoria una frequenza minima del 70% delle lezioni e il completamento minimo del 70% dei compiti assegnati per casa.' },
    { id: 2, name: 'note_second', label: 'In caso di ritiro dalla classe lo studente dovrà pagare una penale di 100,00 euro.' },
    { id: 3, name: 'note_third', label: 'Qualora ci dovesse essere un credito a suo favore, sarà emesso un voucher spendibile entro i 12 mesi e/o cedibile a terzi.' },
    { id: 4, name: 'note_four', label: 'In caso di sospensione del corso, lo studente avrà un anno di tempo dalla sospensione per riprendere le lezioni.' },
]

export function getSidebarItems(){
    return sidebar_items;
}

export function getChannels(){
    return channels;
}

export function getSubscriptions(){
    return subscriptions;
}

export function getCategories(){
    return categories;
}

export function getPaymentTypologies(){
    return payment_typologies;
}

export function getStatiPreventivo(){
    return stati_preventivo;
}

export function getNoteFlags(){
    return note_flags;
}