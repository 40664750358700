<div class="margine-sup mx-5 pb-5 mb-5">
    <div class="border-bottom d-flex justify-content-between">
        <h1>Pannello amministrazione costi</h1>
    </div>

    <div class="border-bottom mt-4">
        <h3>Costi di listino</h3>
        <div class="row mb-3">
            <div class="col-12">
                <form [formGroup]="configurationForm" class="d-flex justify-content-center">
                    <div class="me-3">
                        <label for="tassa-iscrizione" class="mt-2">Tassa iscrizione</label>
                        <input type="number" class="form-control" formControlName="tassaIscrizione">
                    </div>
                    <div>
                        <label for="materiale-didattico" class="mt-2">Materiale didattico</label>
                        <input type="number" class="form-control" formControlName="materialeDidattico">
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="mt-4 d-flex justify-content-between">
        <h3>Scontistiche</h3>
        <button class="btn btn-primary" data-toggle="modal" data-target="#modalCost" (click)="openModal()">
            Aggiungi sconto
        </button>
    </div>
    

    <div class="mt-4">
        <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active" id="nav-tassa" data-bs-toggle="tab" data-bs-target="#nav-all"
                    type="button" role="tab" aria-controls="nav-all" aria-selected="true" (click)="setType(1)">Tassa iscrizione
                </button>

                <button class="nav-link" id="nav-input-tab" data-bs-toggle="tab" data-bs-target="#nav-input"
                    type="button" role="tab" aria-controls="nav-input" aria-selected="false" (click)="setType(2)">Materiale didattico
                </button>
            </div>
        </nav>

        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active mt-3" id="nav-all" role="tabpanel" aria-labelledby="nav-tassa">
                <div *ngIf="(records | async) > 0; else noData" [ngTemplateOutlet]="tableContent"></div>                
            </div>

            <div class="tab-pane fade mt-3" id="nav-input" role="tabpanel" aria-labelledby="nav-input-tab">
                <div *ngIf="(records | async) > 0; else noData" [ngTemplateOutlet]="tableContent"></div>
            </div>
        </div>
    </div>
</div>

<!-- * Tabella costi -->
<ng-template #tableContent>
    <form [formGroup]="costForm">
        <div class="col-4"><input placeholder="Cerca per testo scontistica" formControlName="like_search" (keyup)="getTableData()" class="form-control radiusSelect my-4"></div>
    </form>

    <table mat-table [dataSource]="(dataSource | async)">

        <ng-container matColumnDef="testo">
            <th mat-header-cell *matHeaderCellDef> Testo scontistica </th>
            <td mat-cell *matCellDef="let element"> {{element.testo_scontistica}} </td>
        </ng-container>

        <ng-container matColumnDef="valore">
            <th mat-header-cell *matHeaderCellDef> Percentuale </th>
            <td mat-cell *matCellDef="let element"> {{element.valore}} </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Quantità </th>
            <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
        </ng-container>

        <ng-container matColumnDef="azione">
            <th mat-header-cell *matHeaderCellDef> Azione </th>
            <td mat-cell *matCellDef="let element"> 
                <!-- * Bottone di modifica -->
                <button class="btn btn-outline-dark" (click)="openModal(element)" data-toggle="modal" data-target="#modalCost">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-pencil-fill" viewBox="0 0 16 16">
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                    </svg>
                </button>

                <!-- * Bottone di eliminazione -->
                <button class="btn btn-outline-danger" data-toggle="modal" (click)="setModaldelete(element)" data-target="#deleteElement">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-trash-fill" viewBox="0 0 16 16">
                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                </button>
            </td>
        </ng-container>

        <!-- Intestazioni della tabella -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <!-- Riga dei dati -->
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="(records | async)" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="pageEvent = handlePage($event)" showFirstLastButtons>
    </mat-paginator>
</ng-template>

<!-- * Nessun dato -->
<ng-template #noData>
    <div class="no-cont py-4 fs-4">Nessun risultato riscontrato</div>
</ng-template>

<!-- * Modale costi -->
<div class="modal fade bd-example-modal-sm" id="modalCost" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">{{ isEdit ? 'Modifica costi' : 'Aggiungi costi' }}</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-4">
                <form [formGroup]="costForm">
                    <label for="testo_scontistica" class="mb-1">Selezionare tipo di costo</label>
                    <select class="form-select" aria-label="Default select example" formControlName="type">
                        <option hidden>Filtra per stato </option>
                        <option *ngFor="let value of type" [selected]="value.selected" [value]="value.value">
                            {{value.label}}
                        </option>
                    </select>
                    <label for="testo_scontistica" class="mt-2">Titolo costo</label>
                    <input type="text" class="form-control" formControlName="testo_scontistica">

                    <label for="testo_scontistica" class="mt-2">Percentuale</label>
                    <input type="number" class="form-control" formControlName="valore">

                    <label for="testo_scontistica" class="mt-2">Quantità</label>
                    <input type="number" class="form-control" formControlName="quantity">

                    <div class="d-flex justify-content-between mt-5">
                        <button class="btn btn-secondary" data-dismiss="modal">Annulla</button>
                        <button class="btn color-butt" data-dismiss="modal" (click)="saveCost()">Salva</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- * Modale eliminazione -->
<div class="modal fade bd-example-modal-sm" id="deleteElement" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">Conferma eliminazione</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-4">
                <form [formGroup]="costForm">
                    <p>Desideri eliminare la scontistica "{{ costForm.get('testo_scontistica').value }}"?</p>                  

                    <div class="d-flex justify-content-between mt-5">
                        <button class="btn btn-secondary" data-dismiss="modal">Annulla</button>
                        <button class="btn color-butt" data-dismiss="modal" (click)="deleteElement()">Elimina</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>