<ng-container [formGroup]="form">
    <!-- DATI -->
    <div class="row">
        <div class="col-3 mb-3">
            <app-input [type]="'autocomplete'" [label]="'Intestatario'" [values]="owners" [form]="form" [control]="'owner'" (valueChange)="addStudentLanguage($event)"></app-input>
            <p class="mb-0 error-text" *ngIf="checkOwnerValidity()">Selezionare uno dei risultati dall'elenco</p>
        </div>

        <div class="col-3 mb-3">
            <app-input [type]="'input'" [label]="'Titolo'" [form]="form" [control]="'titolo'"></app-input>
        </div>

        <div class="col-3 mb-3">
            <app-input [type]="'date'" [label]="'Data scadenza'" [form]="form" [control]="'data_scadenza'" [datepickerFilter]="scadenzaDatepickerFilter"></app-input>
        </div>

        <div class="col-3 mb-3">
            <app-input *ngIf="type == 'preventivo'" [type]="'input'" [label]="'Consultant'" [form]="form" [control]="'consultant'"></app-input>
        </div>        
        
        <div class="col mb-3">
            <app-input [type]="'textarea'" [label]="'Note'" [form]="form" [control]="'note'"></app-input>
        </div>
    </div>

    
    <!-- LINGUE -->
    <ng-container>
        <h2 class="mt-5">Lingue</h2>
        
        <ng-container formArrayName="array_lingue">
            <div class="row sub-section" *ngFor="let item of languagesFormArray.controls; let i = index">
                <ng-container [formGroupName]="i">
                    <div class="row">
                        <div class="d-flex justify-content-end mb-2">
                            <i class="fa fa-trash trash-button" matTooltip="Elimina" matTooltipPosition="above" (click)="openLanguagesConfirmModal(i)" onKeyDown=""></i>
                        </div>
                        
                        <div class="col-3 mb-3">
                            <label for="lingua">Lingua</label>
                            <select class="form-select" formControlName="lingua" id="lingua">
                                <option *ngFor="let item of languages" value="{{item.value}}">
                                    {{item.label}}
                                </option>
                            </select>
                        </div>

                        <div class="col-3 mb-3">
                            <label for="livello">Livello</label>
                            <select class="form-select" formControlName="livello" id="livello">
                                <option *ngFor="let item of levels" value="{{item.value}}">
                                    {{item.label}}
                                </option>
                            </select>
                        </div>                        
                        
                        <div class="col-3 mb-3">
                            <label for="livello">Tipologia corso</label>
                            <input class="form-control" formControlName="tipologia_corso" id="tipologia_corso">
                        </div>                          
                        
                        <div class="col-3 mb-3">
                            <label for="totale_ore">Totale ore</label>
                            <input type="number" class="form-control" formControlName="totale_ore" id="totale_ore" min="1">
                        </div>                        
                        
                        <div class="col-3 mb-3">
                            <label for="frequenza">Frequenza</label>
                            <input class="form-control" formControlName="frequenza" id="frequenza">
                        </div>

                        <div class="col-3 mb-3">
                            <label for="durata">Durata (mesi)</label>
                            <input type="number" class="form-control" formControlName="durata" id="durata" min="1">
                        </div>                        
                        
                        <div class="col-3 mb-3">
                            <label for="totale_costo_corso">Costo totale corso</label>
                            <input type="number" class="form-control" formControlName="totale_costo_corso" id="totale_costo_corso" min="1">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3 mb-3 d-flex align-items-center">
                            <mat-slide-toggle color="primary" formControlName="check_sconto_mensile" (change)="resetScontoFields(i)">
                                {{languagesFormArrayByIndex(i).get('check_sconto_mensile').value ? 'Sconto mensile' : 'Sconto libero'}}
                            </mat-slide-toggle>
                        </div>

                        <ng-container *ngIf="languagesFormArrayByIndex(i).get('check_sconto_mensile').value === 0 || !languagesFormArrayByIndex(i).get('check_sconto_mensile').value; else mensile">
                            <div class="col-3 mb-3">
                                <label for="sconto">Sconto %</label>
                                <input type="number" class="form-control" formControlName="sconto" id="sconto" placeholder="Inserisci sconto in %" min="1" max="100">
                            </div>
                        </ng-container>

                        <ng-template #mensile>
                            <div class="col-3 mb-3">
                                <label for="titolo_sconto_mensile">Titolo sconto</label>
                                <input class="form-control" formControlName="titolo_sconto_mensile" id="titolo_sconto_mensile">
                            </div>

                            <div class="col-3 mb-3">
                                <label for="cifra_sconto_mensile">Cifra da scontare</label>
                                <input type="number" class="form-control" formControlName="cifra_sconto_mensile" id="cifra_sconto_mensile">
                            </div>
                        </ng-template>
                    </div>

                    <ng-container *ngIf="isOwnerParentOrCompany">
                        <div class="row d-flex align-items-end mb-1">
                            <div class="col-3">
                                <label for="students_number">Numero studenti</label>
                                <input type="number" class="form-control" formControlName="students_number" id="students_number" min="1">
                            </div>

                            <div class="col-6">
                                <label for="students_number">Ricerca studenti</label>
                                <mat-form-field class="w-100 input-border">
                                    <input type="text" matInput formControlName="students_search" [matAutocomplete]="auto">
                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                        <ng-container *ngFor="let item of students">
                                            <mat-option  [value]="item">{{item.label}}</mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>

                            <div class="col-auto">
                                <button type="button" class="btn color-butt custom-button px-2" (click)="addStudentsFormArrayItem(i)" onKeyDown="" (keydown.enter)="$event.preventDefault()" [disabled]="checkAutocomplete(languagesFormArrayByIndex(i).get('students_search').value)">
                                    Aggiungi studente
                                </button>
                            </div>
                        </div>

                        <div class="m-2">
                            <ul *ngFor="let student of languagesFormArrayByIndex(i).get('students').value; let j = index">
                                <li>
                                    <span class="me-2"> {{student.label}} </span>
                                    <i class="fa fa-trash trash-button" matTooltip="Elimina" matTooltipPosition="above" (click)="openStudentsConfirmModal(i, j)" onKeyDown=""></i>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>

        <div class="row mt-3">
            <div class="col-3">
                <button type="button" class="btn color-butt" (click)="addLanguagesFormArrayItem()" (keydown.enter)="$event.preventDefault()">
                    Aggiungi lingua
                </button>
            </div>
        </div>
    </ng-container>

    <!-- PAGAMENTO -->
    <ng-container>
        <h2 class="mt-5">Pagamento</h2>
        
        <div class="row">
            <div class="col-6 mb-3">
                <app-input [type]="'select'" [label]="'Opzione'" [form]="form" [control]="'method'" [values]="payment_typologies"></app-input>
            </div>
        </div>        
        
        <div class="row" *ngIf="form.get('method').value">
            <div class="col-3 mb-3">
                <app-input [type]="'select'" [label]="'Sconto materiale didattico'" [form]="form" [control]="'sconto_materiale_didattico'" [values]="sconto_materiale_didattico"></app-input>
            </div>

            <div class="col-3 mb-3">
                <app-input [type]="'input'" [inputType]="'number'" [readonly]="form.get('sconto_materiale_didattico').value != 0" [range]="{min: 0}" [label]="'Quantità materiale didattico'" [form]="form" [control]="'costo_materiale_didattico'"></app-input>
                <p class="mb-0">Totale materiale didattico: {{ form.get('totale_materiale_didattico').value | currency:'EUR':'symbol':'1.2-2' }}</p>
            </div>
            
            <div class="col-3 mb-3">
                <app-input [type]="'select'" [label]="'Sconto tassa iscrizione'" [form]="form" [control]="'sconto_tassa_iscrizione'" [values]="sconto_tassa_iscrizione"></app-input>
            </div>

            <div class="col-3 mb-3">
                <app-input [type]="'input'" [inputType]="'number'" [readonly]="form.get('sconto_tassa_iscrizione').value != 0" [range]="{min: 0}" [label]="'Quantità tassa iscrizione'" [form]="form" [control]="'tassa_iscrizione'"></app-input>
                <p class="mb-0">Totale tassa iscrizione: {{ form.get('totale_tassa_iscrizione').value | currency:'EUR':'symbol':'1.2-2' }}</p>
            </div>
            
            <ng-container *ngIf="checkIncludedFields(form, 'method', [2, 3, 4])">
                <div class="col-3 mb-3">
                    <app-input [type]="'input'" [inputType]="'number'" [readonly]="true" [label]="'Prima quota'" [tooltipText]="form.get('method').value == '2' ? 'Materiale didattico + iscrizione' : (form.get('method').value == '4' ? 'Materiale didattico + iscrizione + acconto' : 'Materiale didattico + iscrizione + prima rata del costo totale')" [form]="form" [control]="'prima_rata'"></app-input>
                </div>
                
                <div class="col-3 mb-3">
                    <app-input [type]="'input'" [inputType]="'number'" [readonly]="true" [label]="'Importo rata'" [form]="form" [control]="'importo_rata'"></app-input>
                </div>

                <div class="col-3 mb-3">
                    <app-input [type]="'input'" [inputType]="'number'" [label]="form.get('method').value == '2' ? 'Numero di rate' : 'Rate totali'" [form]="form" [control]="'numero_rate'"></app-input>
                </div>

                <div class="col-3 mb-3">
                    <app-input [type]="'input'" [inputType]="'number'" [readonly]="true" [label]="'Rate rimanenti'" [form]="form" [control]="'rate_rimanenti'"></app-input>
                </div>
            </ng-container>            
            
            <ng-container *ngIf="checkIncludedFields(form, 'method', [4])">
                <div class="col-3 mb-3">
                    <app-input [type]="'input'" [inputType]="'number'" [label]="'Acconto'" [form]="form" [control]="'acconto'"></app-input>
                </div>
            </ng-container>

            <div class="col-3 mb-3">
                <app-input [type]="'input'" [inputType]="'number'" [readonly]="true" [label]="'Costo totale corsi'" [form]="form" [control]="'costo_totale_corsi'"></app-input>
            </div>
            
            <div class="col-3 mb-3">
                <app-input [type]="'input'" [inputType]="'number'" [readonly]="true" [label]="'Totale da saldare'" [form]="form" [control]="'totale_saldare'"></app-input>
            </div>
        </div>
    </ng-container>

    <!-- STATO -->
    <div class="row" *ngIf="action == 'update'">
        <div class="col-6 mb-3">
            <app-input [type]="'select'" [readonly]="readonlyState" [label]="'Stato'" [form]="form" [control]="'stato'" [values]="stati_preventivo"></app-input>
        </div>        
        
        <div class="col-3 mb-3" *ngIf="form.get('stato').value == 'APPROVATO' && form.get('numero_rate').value > 0 && type == 'contratto'">
            <app-input [type]="'date'" [label]="'Data inizio pagamento'" [form]="form" [control]="'data_inizio_pagamento'"></app-input>
        </div>
    </div>
    
    <!-- NOTE AGGIUNTIVE -->
    <div class="row" *ngIf="type == 'contratto'">
        <h2 class="mt-5">Note aggiuntive</h2>

        <div class="form-group mb-3">
            <div *ngFor="let flag of note_flags" class="form-check mb-2">
                <input id="check-{{flag.id}}" class="form-check-input" type="checkbox"
                       [formControlName]="flag.name">
                <label for="check-{{flag.id}}">{{ flag.label }}</label>
            </div>
        </div>

        <div class="col mb-3">
            <app-input [type]="'textarea'" [label]="'Altre note'" [form]="form" [control]="'altre_note'"></app-input>
        </div>
    </div>

    <div class="d-flex justify-content-end mt-5">
        <button class="btn color-butt" (click)="onSave()">
            Salva
        </button>
    </div>
</ng-container>

<!-- MODALE DI COINFERMA PER ELIMINAZIONE LINGUA/STUDENTE -->
<ng-template #confirmModal>
    <div class="modal-header mb-4 ps-0">
        <h2 class="m-0 p-0">{{confirm_modal_info.title}}</h2>
        <button type="button" class="btn-close" (click)="_dialog.closeAll()"></button>
    </div>

    <p>{{confirm_modal_info.text}}</p>

    <div class="text-end">
        <button type="button" class="btn" [ngClass]="confirm_modal_info.button_class" (click)="confirm_modal_info.callback()">
            Conferma
        </button>
    </div>
</ng-template>