import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { AnagraficaService } from 'src/app/services/anagraphic.service';
import { ClasseService } from 'src/app/services/class.service';
import { PreventiviService } from 'src/app/services/preventivi.service';
import { Router } from "@angular/router";
import { PaymentType } from 'src/app/utilities/payment-type.enum';

@Component({
  selector: 'app-preventivi',
  templateUrl: './preventivi.component.html',
  styleUrls: ['./preventivi.component.scss']
})
export class PreventiviComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('confirmModalModifyButton') confirmModalModifyButton;
  @ViewChild('confirmModalButton') confirmModalButton;

  preventivi: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  displayedColumns: string[] = ['nome', 'studente', 'totale_costo_corso', 'stato', 'data_creazione', 'azione'];
  currentPage = 0;
  currentPageContratti = 0;
  formNuovoPreventivo: FormGroup;
  pageSize = 10;
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  recordsContratti: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  lingue_studente: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  pageEvent: PageEvent;
  pageEventContratti: PageEvent;
  array_lingue: FormArray;
  filter = null;
  filterContratti = null;
  pageSizeContratti = 10;
  typeSaving: string = '';
  allLingue: any;
  livelloLingua: any;
  titoloPreventivo = [
    { nome: 'Classe 2/3 partecipanti' },
    { nome: 'Classe 4/5 partecipanti' },
    { nome: 'Classe 6 partecipanti' },
    { nome: 'Classe 6/8 partecipanti' },
    { nome: 'Corso individuale' },
    { nome: 'Pacchetto conversation' }
  ];

  flag = 1;
  fl = null;
  typology = [
    { id: 1, label: 'Unica soluzione' }, // Ex Classico
    { id: 2, label: 'Preventivo con acconto' }, // Ex Alternativo
    { id: 3, label: 'Preventivo con acconto e prima rata' }, // Ex Personalizzato
    { id: 4, label: 'Preventivo con acconto manuale' }
  ]

  giorni: string[] = ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"]

  filtro_opzione: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  anagrafiche: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  lingue: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  livelli: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  contratti: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  deleteContent: any;
  stati: { id: string; label: string; }[];
  visualizzaStudenti = false;
  studentis: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  array: any[];
  formArrayLenght: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  dateForm: FormGroup;
  method: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  method_installment: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  filtro_ricerca: any;
  isApproved: boolean = false;
  today: any;
  minEndDate: any;
  selectedPreventivoId = null;
  prev = null;

  sconto_tassa_iscrizione = [
    { lable: "Tassa iscrizione omaggio", value: 0 },
    { lable: "Tassa iscrizione -50%", value: 30 },
    { lable: "2 tassa iscrizione -50%", value: 50 },
    { lable: "3 tassa iscrizione", value: 60 }
  ]

  sconto_materiale_didattico = [
    { lable: "No materiale didattico", value: 0 },
    { lable: "Materiale didattico omaggio", value: '0' },
    { lable: "Materiale didattico -50%", value: 25 },
    { lable: "2 materiale didattico -50%", value: 50 }
  ]

  note_flags = [
    { id: 1, name: 'note_one', label: 'Ai fini del raggiungimento del livello, è obbligatoria una frequenza minima del 70% delle lezioni e il completamento minimo del 70% dei compiti assegnati per casa.' },
    { id: 2, name: 'note_second', label: 'In caso di ritiro dalla classe lo studente dovrà pagare una penale di 100,00 euro.' },
    { id: 3, name: 'note_third', label: 'Qualora ci dovesse essere un credito a suo favore, sarà emesso un voucher spendibile entro i 12 mesi e/o cedibile a terzi.' },
    { id: 4, name: 'note_four', label: 'In caso di sospensione del corso, lo studente avrà un anno di tempo dalla sospensione per riprendere le lezioni.' },
  ]

  selectedScontoMaterialeDidattico: number | null = null;
  selectedScontoTassaIscrizione: number | null = null;
  totaleMaterialeDidattico: number = 0;
  totaleTassaIscrizione: number = 0;

  // Campi per cui applicare la formattazione
  fieldsToFormat: string[] = ['totale_saldare', 'prima_rata', 'importo_rata'];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private _anagraficaService: AnagraficaService,
    private fb: FormBuilder,
    private preventivi_service: PreventiviService,
    private _toastr: ToastrService,
    private dateAdapter: DateAdapter<Date>,
    private classService: ClasseService,
    public router: Router
  ) {
    super();
    this.dateAdapter.setLocale('it-IT');
    this.formNuovoPreventivo = this.fb.group({
      id: null,
      nome: [null],
      consultant: [null],
      studente_id: [null],
      array_lingue: this.fb.array([]),
      costo_materiale_didattico: [0, Validators.min(0)],
      tassa_iscrizione: [0, Validators.min(0)],
      sconto_materiale_didattico: [null],
      sconto_tassa_iscrizione: [null],
      check_sconto_materiale: [null],
      check_sconto_tassa: [null],
      materiale_didattico_omaggio: [null],
      sconto: [null],
      check_sconto_mensile: [false],
      cifra_sconto_mensile: [null],
      titolo_sconto_mensile: [null],
      acconto: [0],
      note: [null],
      totale_costo_corso: [null, Validators.required],
      totale_saldare: [null, Validators.required],
      numero_rate: [null],
      importo_rata: [null],
      prima_rata: [null],
      rate_rimanenti: [null],
      method: [null],
      stato: [null],
      search_student: [null],
      data_scadenza: [null],
      data_inizio_pagamento: [null],
      // note_checkboxes: this.fb.group(this.createNoteControls()),
      note_one: [false],
      note_second: [false],
      note_third: [false],
      note_four: [false],
      altre_note: [null]
    });

    this.dateForm = this.fb.group({
      data_inizio: [null],
      data_fine: [null]
    });

    this.dateForm.get('data_inizio').valueChanges.subscribe(value => {
      this.minEndDate = value;
    });

    this.stati = [
      {
        id: "IN CORSO", label: "IN CORSO"
      },
      {
        id: "DA APPROVARE", label: "DA APPROVARE"
      },
      {
        id: "APPROVATO", label: "APPROVATO"
      }]
  }

  ngOnInit(): void {

    this.today = new Date().toISOString().split("T")[0];
    $('.tooltipevent').remove();
    let year = new Date().getFullYear();
    let first_date = new Date(year, 0, 1);
    let last_date = new Date(year, 11, 31);
    let first_date2 = (this.formatDate(first_date));
    let last_date2 = (this.formatDate(last_date));
    this.dateForm.patchValue({ data_inizio: first_date2 });
    this.dateForm.patchValue({ data_fine: last_date2 });
    this.getData();
    this.getLangLevelTipology();

    this.formNuovoPreventivo.get('nome')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.array_lingue = this.formNuovoPreventivo.get('array_lingue') as FormArray;

      for (let j = 0; j < this.array_lingue.controls.length; j++) {
        const formGroup = this.array_lingue.at(j) as FormGroup;
        formGroup.get('tipologia_corso')?.setValue(value);
      }
    });

    this.formNuovoPreventivo.get('sconto_materiale_didattico')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value == null && this.formNuovoPreventivo.get('costo_materiale_didattico').value == null) {
        this.formNuovoPreventivo.patchValue({
          costo_materiale_didattico: 1
        });
      }
    });

    this.formNuovoPreventivo.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.formatFields();
    });

    this.onChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleDiscount() {
    this.formNuovoPreventivo.value.check_sconto_mensile = !this.formNuovoPreventivo.value.check_sconto_mensile;
  }

  get isDiscountMonthly() {
    return this.formNuovoPreventivo.get('check_sconto_mensile')?.value;
  }

  onChanges(): void {
    this.formNuovoPreventivo.get('check_sconto_mensile')?.valueChanges.subscribe(isMonthly => {
      if (isMonthly) {
        this.formNuovoPreventivo.patchValue({
          sconto: null
        });
        this.formNuovoPreventivo.get('sconto')?.clearValidators();
        this.formNuovoPreventivo.get('sconto')?.updateValueAndValidity();
      } else {
        this.formNuovoPreventivo.patchValue({
          titolo_sconto_mensile: null,
          cifra_sconto_mensile: null
        });
        this.formNuovoPreventivo.get('titolo_sconto_mensile')?.clearValidators();
        this.formNuovoPreventivo.get('titolo_sconto_mensile')?.updateValueAndValidity();
        this.formNuovoPreventivo.get('cifra_sconto_mensile')?.clearValidators();
        this.formNuovoPreventivo.get('cifra_sconto_mensile')?.updateValueAndValidity();
      }
    });
    if (this.filtro_opzione.value == 1 || this.method.value == PaymentType.UNICA_SOLUZIONE) {
      this.setTotal();
    } else if ((this.filtro_opzione.value == 2 || this.method.value == PaymentType.PREVENTIVO_ACCONTO)) {
      this.setTotal2();
    } else if ((this.filtro_opzione.value == 3 || this.method.value == PaymentType.PREVENTIVO_ACCONTO_PRIMA_RATA)) {
      this.generateRate3();
    } else if ((this.filtro_opzione.value == 4 || this.method.value == PaymentType.PREVENTIVO_ACCONTO_MANUALE)) {
      this.generateRate4();
    }
  }


  onStartDateChange(): void {
    const startDate = this.dateForm.get('data_inizio').value;
    if (startDate) {
      this.minEndDate = startDate;
    }
  }

  formatFields() {
    Object.keys(this.formNuovoPreventivo.controls).forEach(key => {
      if (this.fieldsToFormat.includes(key)) {
        const control = this.formNuovoPreventivo.get(key);
        if (control && typeof control.value === 'number') {
          control.setValue(parseFloat(control.value.toFixed(2)), { emitEvent: false });
        }
      }
    });
  }

  getData() {
    this.getAllPreventivi();
    this.getAllContratti();
    this.getStudenti();
  }

  updateMaterialeDidatticoCost(event?) {
    const scontoMateriale = this.formNuovoPreventivo.get('sconto_materiale_didattico').value;
    const quantitaMateriale = this.formNuovoPreventivo.get('costo_materiale_didattico').value;

    if (scontoMateriale !== null) {
      if (scontoMateriale == '0') this.totaleMaterialeDidattico = 0;
      else this.totaleMaterialeDidattico = scontoMateriale;
    } else {
      this.totaleMaterialeDidattico = quantitaMateriale * 50;
    }

    if (event) {
      const selectedIndex = event.target.selectedIndex - 1;
      const selectedSconto = this.sconto_materiale_didattico[selectedIndex];
      const selectedLabel = selectedSconto ? selectedSconto.lable : null;

      if (selectedLabel === "Materiale didattico omaggio") {
        this.formNuovoPreventivo.patchValue({
          materiale_didattico_omaggio: true
        });
      } else {
        this.formNuovoPreventivo.patchValue({
          materiale_didattico_omaggio: false
        });
      }
    }

    if (this.filtro_opzione.value == 1 || this.method.value == PaymentType.UNICA_SOLUZIONE) {
      let totale_costo_corso = this.formNuovoPreventivo.get('totale_costo_corso').value
      totale_costo_corso = Number(totale_costo_corso);
      totale_costo_corso = totale_costo_corso + this.totaleMaterialeDidattico + this.totaleTassaIscrizione;
      this.setTotal();
    }
    else if (this.filtro_opzione.value == 2 || this.method.value == PaymentType.PREVENTIVO_ACCONTO) {
      let prima_rata = this.totaleMaterialeDidattico + this.totaleTassaIscrizione;
      this.formNuovoPreventivo.patchValue({
        prima_rata: prima_rata
      });
    }
    else if (this.filtro_opzione.value == 3 || this.method.value == PaymentType.PREVENTIVO_ACCONTO_PRIMA_RATA) {
      this.formNuovoPreventivo.patchValue({
        prima_rata: this.totaleMaterialeDidattico + this.totaleTassaIscrizione + Number(this.formNuovoPreventivo.get('importo_rata').value)
      });
    }
    else if (this.filtro_opzione.value == 4 || this.method.value == PaymentType.PREVENTIVO_ACCONTO_MANUALE) {
      this.formNuovoPreventivo.patchValue({
        prima_rata: this.totaleMaterialeDidattico + this.totaleTassaIscrizione + Number(this.formNuovoPreventivo.get('acconto').value)
      });
    }
  }

  updateTassaIscrizioneCost() {
    const scontoTassa = this.formNuovoPreventivo.get('sconto_tassa_iscrizione').value;
    const quantitaTassa = this.formNuovoPreventivo.get('tassa_iscrizione').value;

    if (scontoTassa !== null) {
      this.totaleTassaIscrizione = scontoTassa;
    } else {
      this.totaleTassaIscrizione = quantitaTassa * 60;
    }

    if (this.filtro_opzione.value == 1 || this.method.value == PaymentType.UNICA_SOLUZIONE) {
      let totale_costo_corso = this.formNuovoPreventivo.get('totale_costo_corso').value
      totale_costo_corso = Number(totale_costo_corso);
      totale_costo_corso = totale_costo_corso + this.totaleMaterialeDidattico + this.totaleTassaIscrizione;
      this.setTotal();
    }
    else if (this.filtro_opzione.value == 2 || this.method.value == PaymentType.PREVENTIVO_ACCONTO) {
      let prima_rata = this.totaleMaterialeDidattico + this.totaleTassaIscrizione;
      this.formNuovoPreventivo.patchValue({
        prima_rata: prima_rata
      });
    }
    else if (this.filtro_opzione.value == 3 || this.method.value == PaymentType.PREVENTIVO_ACCONTO_PRIMA_RATA) {
      this.formNuovoPreventivo.patchValue({
        prima_rata: this.totaleMaterialeDidattico + this.totaleTassaIscrizione + Number(this.formNuovoPreventivo.get('importo_rata').value)
      });
    }
    else if (this.filtro_opzione.value == 4 || this.method.value == PaymentType.PREVENTIVO_ACCONTO_MANUALE) {
      this.formNuovoPreventivo.patchValue({
        prima_rata: this.totaleMaterialeDidattico + this.totaleTassaIscrizione + Number(this.formNuovoPreventivo.get('acconto').value)
      });
    }
  }

  getFormattedLabel(sconto: any): string {
    const parts = sconto.lable.split(' euro ');
    if (parts.length > 1) {
      return `${parts[0]} <s>euro ${parts[1]}</s>`;
    }
    return sconto.lable;
  }

  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  openConfirmModal(type: string) {
    if (this.formNuovoPreventivo.value.data_scadenza != null) {
      this.formNuovoPreventivo.patchValue({
        data_scadenza: this.getFormattedDate(this.formNuovoPreventivo.value.data_scadenza, 'YYYY-MM-DD'),
      });
    }
    if (this.formNuovoPreventivo.value.totale_costo_corso == null || this.formNuovoPreventivo.value.totale_costo_corso == 0) {
      this._toastr.warning('Inserire costo del corso');
    } else if ((this.filtro_opzione.value == 2 || this.filtro_opzione.value == 3 || this.filtro_opzione.value == 4) && (this.formNuovoPreventivo.value.numero_rate == null || this.formNuovoPreventivo.value.numero_rate == 0)) {
      this._toastr.warning('Inserire il numero di rate');
    } else {
      this.typeSaving = type;
      this.confirmModalButton.nativeElement.click();
    }
  }

  closeConfirmModal(self?: string) {
    $('#confirmModal').hide();
    if (self != 'self') {
      setTimeout(() => {
        let addModalPreventivo = document.getElementById('addModalPreventivo');
        addModalPreventivo.style.display = 'none';
        let addModalContratto = document.getElementById('addModalContratto');
        addModalContratto.style.display = 'none';
      }, 100);
      $('.modal-backdrop').hide();
    }
  }

  openConfirmModalModify(type: string) {
    if (this.formNuovoPreventivo.value.data_scadenza != null) {
      this.formNuovoPreventivo.patchValue({
        data_scadenza: this.getFormattedDate(this.formNuovoPreventivo.value.data_scadenza, 'YYYY-MM-DD'),
      });
    }

    if (this.formNuovoPreventivo.value.data_inizio_pagamento != null) {
      this.formNuovoPreventivo.patchValue({
        data_inizio_pagamento: this.getFormattedDate(this.formNuovoPreventivo.value.data_inizio_pagamento, 'YYYY-MM-DD'),
      });
    }
    this.typeSaving = type;
    this.confirmModalModifyButton.nativeElement.click();
  }

  closeConfirmModalModify(self?: string) {
    $('#confirmModalModify').hide();
    if (self != 'self') {
      setTimeout(() => {
        let editModalPreventivo = document.getElementById('editModalPreventivo');
        editModalPreventivo.style.display = 'none';
        let editModalContratto = document.getElementById('editModalContratto');
        editModalContratto.style.display = 'none';
      }, 100);
      $('.modal-backdrop').hide();
    }
  }

  setDate() {
    this.dateForm.patchValue({
      data_inizio: this.getFormattedDate(this.dateForm.value.data_inizio, 'YYYY-MM-DD'),
      data_fine: this.getFormattedDate(this.dateForm.value.data_fine, 'YYYY-MM-DD')
    });
    if (this.dateForm.value.data_inizio > this.dateForm.value.data_fine) {
      this._toastr.error('Data fine antecedente alla data di inizio');
    } else {
      this.getAllPreventivi();
      this.getAllContratti();

    }
  }

  resetDate() {
    let year = new Date().getFullYear();
    let first_date = new Date(year, 0, 1);
    let last_date = new Date(year, 11, 31);
    let first_date2 = (this.formatDate(first_date));
    let last_date2 = (this.formatDate(last_date));
    this.dateForm.patchValue({ data_inizio: first_date2 });
    this.dateForm.patchValue({ data_fine: last_date2 });
    this.minEndDate = null;
    this.getAllPreventivi()
    this.getAllContratti();
  }

  getFormattedDate(date: moment.MomentInput, format: string | undefined) {
    return moment(date).locale('it').format(format);
  }

  setFlag(num) {
    this.flag = num;
  }

  // * GENERAZIONE PDF //
  generatePdf(element) {
    this.preventivi_service.createPdf({ id: element.id, file_type: 'Preventivo' }).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.createOpenPdf(response);
    });
  }

  getLingueByStudent() {
    this.array = new Array();
    this._anagraficaService.getLingueByStudent({ studente_id: this.formNuovoPreventivo.value.studente_id })
      .then(response => {
        this.lingue_studente.next(response.result);
        this.array = response.result;
        this.setFormArray();
      });
  }

  setFormArray() {
    //LO PULISCO TUTTO//
    this.array_lingue = this.formNuovoPreventivo.get('array_lingue') as FormArray;
    while (this.array_lingue.length !== 0) {
      this.array_lingue.removeAt(0)
    }
    //PUSHO I MIEI ELEMENTI DEL FORM ARRAY//
    for (const j of this.array) {
      this.addGroup(j);
    }
    if (this.array.length > 0) {
      this.fl = "si";
    } else {
      this.fl = "no_lingue";
    }
  }

  addGroup(lingua?) {
    debugger;
    (<FormArray>this.formNuovoPreventivo.controls['array_lingue']).push(
      new FormGroup({
        lingua: new FormControl(lingua?.lingua ?? null),
        livello: new FormControl(lingua?.livello ?? null),
        tipologia_corso: new FormControl(lingua?.tipologia_corso ?? this.formNuovoPreventivo.value.nome ?? null),
        totale_ore: new FormControl(lingua?.totale_ore ?? null),
        frequenza: new FormControl(lingua?.frequenza ?? null),
        durata: new FormControl(lingua?.durata ?? null),
      })
    );
  }

  generatePdfContract(element) {
    this.preventivi_service.createPdf({ id: element.id, file_type: 'Contratto' }).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.createOpenPdf(response);
    });
  }

  //OTTENGO GLI STUDENTI
  getStudenti() {
    this._anagraficaService.getStudent().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.anagrafiche.next(res.result);
    })
  }

  searchStudents() {
    let params = { like_search: this.formNuovoPreventivo.value.search_student, representatives: true };
    this._anagraficaService.searchStudents(params).pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.result && response.result.length > 0) {
        this.visualizzaStudenti = true;
      } else {
        this.visualizzaStudenti = false;
      }
      this.studentis.next(response.result);
    });
  }

  displayStudent(student: any): string {
    return student ? `${student.name} ${student.surname}` : '';
  }

  setStudents(stud) {
    this.formNuovoPreventivo.patchValue({
      studente_id: stud.id,
      search_student: stud.name + " " + stud.surname
    })
    this.visualizzaStudenti = false
    this.getLingueByStudent();
  }

  //GESTISCE LA DATA TABLE//
  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAllPreventivi();
    return e;
  }

  //OTTENGO I PREVENTIVI//
  getAllPreventivi() {
    this.preventivi_service.getAllPreventivi({ search_field: this.filter, skip: (this.currentPage) * this.pageSize, take: this.pageSize, data_inizio: this.dateForm.value.data_inizio, data_fine: this.dateForm.value.data_fine })
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.records.next(response.total);
        this.preventivi.next(response.result);
      })
  }

  //GESTISCO DATA TABLE CONTRATTI//
  handlePageContratti(e: any) {
    this.currentPageContratti = e.pageIndex;
    this.pageSizeContratti = e.pageSize;
    this.getAllContratti();
    return e;
  }
  //OTTENGO I CONTRATTI//
  getLangLevelTipology() {
    this.classService.getLangLevelTypology().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.allLingue = res.lingue;
      this.livelloLingua = res.livelli;
    })
  }

  getAllContratti() {
    this.preventivi_service.getAllContratti({ search_field: this.filterContratti, skip: (this.currentPageContratti) * this.pageSizeContratti, take: this.pageSizeContratti, data_inizio: this.dateForm.value.data_inizio, data_fine: this.dateForm.value.data_fine })
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.recordsContratti.next(response.total);
        this.contratti.next(response.result);
      })
  }

  //CREA ELEMENTO FORM ARRAY//
  createItem(): FormGroup {
    let element = this.fb.group({
      lingua: '',
      livello: '',
      tipologia_corso: this.formNuovoPreventivo.controls.nome,
      totale_ore: '',
      frequenza: '',
      durata: ''
    });
    return element;
  }

  //GESTIONE DELETE FORM ARRAY//
  deleteAddressGroup(index: number) {
    const deleteelemento = this.formNuovoPreventivo.get('array_lingue') as FormArray;
    deleteelemento.removeAt(index);
  }



  //AGGIUNGI PREVENTIVO//
  addPreventivo() {
    this.formNuovoPreventivo.patchValue({
      costo_materiale_didattico: this.totaleMaterialeDidattico,
      tassa_iscrizione: this.totaleTassaIscrizione,
    })

    if (this.formNuovoPreventivo.get('sconto_materiale_didattico').value != null) {
      this.formNuovoPreventivo.patchValue({
        check_sconto_materiale: true,
      })
    } else {
      this.formNuovoPreventivo.patchValue({
        check_sconto_materiale: false,
      })
    }

    if (this.formNuovoPreventivo.get('sconto_tassa_iscrizione').value != null) {
      this.formNuovoPreventivo.patchValue({
        check_sconto_tassa: true,
      })
    } else {
      this.formNuovoPreventivo.patchValue({
        check_sconto_tassa: false,
      })
    }
    const formValue = { ...this.formNuovoPreventivo.value, ...{ notes_array: this.note_flags } };

    this.preventivi_service.addPreventivo(formValue).pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.status == false) {
        this._toastr.error(response.result);
      } else {
        this._toastr.success("Preventivo creato con successo");
        this.getAllPreventivi();
        this.resetForm();
      }
    })
  }

  //AGGIUNGO CONTRATTO//
  addContratto() {
    this.formNuovoPreventivo.patchValue({
      costo_materiale_didattico: this.totaleMaterialeDidattico,
      tassa_iscrizione: this.totaleTassaIscrizione,
    })

    if (this.formNuovoPreventivo.get('sconto_materiale_didattico').value != null) {
      this.formNuovoPreventivo.patchValue({
        check_sconto_materiale: true,
      })
    } else {
      this.formNuovoPreventivo.patchValue({
        check_sconto_materiale: false,
      })
    }

    if (this.formNuovoPreventivo.get('sconto_tassa_iscrizione').value != null) {
      this.formNuovoPreventivo.patchValue({
        check_sconto_tassa: true,
      })
    } else {
      this.formNuovoPreventivo.patchValue({
        check_sconto_tassa: false,
      })
    }

    this.preventivi_service.addContratto({ ...this.formNuovoPreventivo.value, ...{ notes_array: this.note_flags } }).pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.status == false) {
        this._toastr.error(response.result);
      } else {
        this._toastr.success("Contratto creato con successo");
        this.getAllContratti();
        this.resetForm();
      }
    })
  }

  //SETTA ELEMENTO DA ELIMINARE//
  setDeleteElement(element) {
    this.deleteContent = element;
  }

  //ELIMINA PREVENTIVO//
  deletePreventivo(id) {
    this.preventivi_service.deletePreventivo({ id: id }).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this._toastr.success("Preventivo eliminato con successo");
      this.getAllPreventivi();
    })
  }

  //ELIMINA CONTRATTO//
  deleteContratto(id) {
    this.preventivi_service.deleteContratto({ id: id }).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this._toastr.success("Contratto eliminato con successo");
      this.getAllContratti();
    })
  }

  convertToString(value: any): string {
    return value !== null && value !== undefined ? value.toString() : '';
  }

  //SETTA L'ELEMENTO DA MODIFICARE//
  editPreventivo(element) {
    this.resetForm();

    this.method.next(element.method);
    this.array_lingue = this.formNuovoPreventivo.get('array_lingue') as FormArray;

    for (const j of element.array_lingue) {
      this.addGroup(j);
    }

    this._anagraficaService.getMemberById({ user_id: element.studente_id }).pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (element.materiale_didattico_omaggio == 1) {
        element.sconto_materiale_didattico = this.convertToString(element.sconto_materiale_didattico);
      }

      const scontoMateriale = element.sconto_materiale_didattico !== null ? this.sconto_materiale_didattico.find(s => s.value === element.sconto_materiale_didattico) : null;
      const scontoIscrizione = element.sconto_tassa_iscrizione !== null ? this.sconto_tassa_iscrizione.find(s => s.value === element.sconto_tassa_iscrizione) : null;

      this.totaleMaterialeDidattico = element.costo_materiale_didattico;
      this.totaleTassaIscrizione = element.tassa_iscrizione;

      this.formNuovoPreventivo.patchValue({
        nome: element.titolo,
        studente_id: element.studente_id,
        search_student: res.result ? res.result.name + " " + res.result.surname : '',
        consultant: element.consultant,
        costo_materiale_didattico: scontoMateriale ? null : element.costo_materiale_didattico / 50,
        sconto_materiale_didattico: scontoMateriale ? scontoMateriale.value : null,
        tassa_iscrizione: scontoIscrizione ? null : element.tassa_iscrizione / 60,
        sconto_tassa_iscrizione: scontoIscrizione ? scontoIscrizione.value : null,
        sconto: element.sconto,
        check_sconto_mensile: element.check_sconto_mensile,
        cifra_sconto_mensile: element.cifra_sconto_mensile,
        titolo_sconto_mensile: element.titolo_sconto_mensile,
        acconto: element.acconto,
        note: element.note,
        totale_costo_corso: element.totale_costo_corso,
        numero_rate: element.numero_rate,
        importo_rata: element.importo_rata,
        prima_rata: element.prima_rata,
        rate_rimanenti: element.rate_rimanenti,
        method: element.method,
        stato: element.stato,
        totale_saldare: Number(element.totale_saldare),
        id: element.id,
        data_scadenza: element.data_scadenza,
        data_inizio_pagamento: element.data_inizio_pagamento,
        note_one: element.note_documento?.find((e) => e.type_id == 1)?.value ?? false,
        note_second: element.note_documento?.find((e) => e.type_id == 2)?.value ?? false,
        note_third: element.note_documento?.find((e) => e.type_id == 3)?.value ?? false,
        note_four: element.note_documento?.find((e) => e.type_id == 4)?.value ?? false,
        altre_note: element.altre_note
      })

      this.updateMaterialeDidatticoCost();
      this.updateTassaIscrizioneCost();
    })
  }

  getControls() {
    return (this.formNuovoPreventivo.get('array_lingue') as FormArray).controls;
  }

  maxWords(event: any) {
    const enteredValue: number = event.target.value;
    if (enteredValue > 100) {
      event.target.value = 100;
    }
  }

  setTotal() {
    let costo_materiale_didattico = this.totaleMaterialeDidattico;
    let costo_iscrizione = this.totaleTassaIscrizione;
    let costo_totale_corso = Number(this.formNuovoPreventivo.value.totale_costo_corso) || 0;

    let sconto_applicato = 0;
    if (this.isDiscountMonthly) {
      sconto_applicato = this.formNuovoPreventivo.value.cifra_sconto_mensile || 0;
    } else {
      let sconto = this.formNuovoPreventivo.value.sconto || 0;
      sconto_applicato = costo_totale_corso * (sconto / 100);
    }

    let totale_senza_sconto = costo_iscrizione + costo_materiale_didattico + costo_totale_corso;
    let totale_saldare = totale_senza_sconto - sconto_applicato;

    this.formNuovoPreventivo.patchValue({ totale_saldare: totale_saldare });
  }

  setTotal2() {
    let costo_materiale_didattico = this.totaleMaterialeDidattico;
    let costo_iscrizione = this.totaleTassaIscrizione;
    let costo_totale_corso = this.formNuovoPreventivo.value.totale_costo_corso;

    let sconto_applicato = 0;
    if (this.isDiscountMonthly) {
      sconto_applicato = this.formNuovoPreventivo.value.cifra_sconto_mensile || 0;
    } else {
      let sconto = this.formNuovoPreventivo.value.sconto || 0;
      sconto_applicato = costo_totale_corso * (sconto / 100);
    }

    // * Rimuoviamo la prima quota dal totale da saldare
    // let totale_senza_sconto = costo_iscrizione + costo_materiale_didattico + costo_totale_corso;
    // let totale_saldare = totale_senza_sconto - sconto_applicato;
    let totale_saldare = costo_totale_corso - sconto_applicato;
    this.formNuovoPreventivo.patchValue({ totale_saldare: totale_saldare });
    this.generateRate2();

    let prima_rata = (costo_iscrizione + costo_materiale_didattico);
    this.formNuovoPreventivo.patchValue({ prima_rata: prima_rata })
  }


  setTotal3() {
    let costo_materiale_didattico = this.formNuovoPreventivo.value.costo_materiale_didattico;
    let costo_iscrizione = this.formNuovoPreventivo.value.tassa_iscrizione;
    let costo_totale_corso = this.formNuovoPreventivo.value.totale_costo_corso;
    // let prima_rata = costo_iscrizione + costo_materiale_didattico + data.importo;
  }

  onInputChange(event: any) {
    const inputValue = parseFloat(event.target.value);
    if (!isNaN(inputValue)) {
      this.formNuovoPreventivo.get('importo_rata').setValue(inputValue.toFixed(2), { emitEvent: false });
    }
  }

  generateRate() {
    let costo_totale = this.formNuovoPreventivo.value.totale_saldare;
    if (costo_totale == null) {
      this._toastr.error("Inserisci il costo totale da saldare");
    }

    let rate = this.formNuovoPreventivo.value.numero_rate;
    let importo = costo_totale / rate;
    this.formNuovoPreventivo.patchValue({
      importo_rata: importo,
      rate_rimanenti: rate
    })

    return { costo_totale, rate, importo };
  }

  generateRate2() {
    let costo_totale = this.formNuovoPreventivo.value.totale_saldare;
    if (costo_totale == null) {
      this._toastr.error("Inserisci il costo totale da saldare");
    }
    let rate = this.formNuovoPreventivo.value.numero_rate;
    let new_rate = rate;
    let importo = costo_totale / new_rate;

    let importoRounded = parseFloat(importo.toFixed(2));

    this.formNuovoPreventivo.patchValue({
      numero_rate: rate,
      importo_rata: importoRounded,
      rate_rimanenti: new_rate
    })

    return { costo_totale, rate, importo: importoRounded };
  }

  generateRate31() {
    let costo_totale_corso = this.formNuovoPreventivo.value.totale_costo_corso;
    let sconto = this.formNuovoPreventivo.value.sconto || 0;

    let sconto_applicato = costo_totale_corso * (sconto / 100);
    let costo_totale_corso_scontato = costo_totale_corso - sconto_applicato;

    let rate = this.formNuovoPreventivo.value.numero_rate;
    let new_rate = rate - 1;
    let rata_1 = costo_totale_corso_scontato / rate;

    let totale_saldare = costo_totale_corso_scontato - rata_1;
    let prima_rata = (this.totaleMaterialeDidattico + this.totaleTassaIscrizione + rata_1);

    let importo = totale_saldare / new_rate;
    let importoRounded = parseFloat(importo.toFixed(2));
    let totaleRounded = parseFloat(totale_saldare.toFixed(2));
    let rataRounded = parseFloat(prima_rata.toFixed(2));


    this.formNuovoPreventivo.patchValue({
      importo_rata: importoRounded,
      totale_saldare: totaleRounded,
      prima_rata: rataRounded,
      rate_rimanenti: new_rate
    });


    return { new_rate, totale_saldare: totaleRounded };
  }

  generateRate3() {

    // Estrai i valori dal form
    let costo_totale_corso = this.formNuovoPreventivo.value.totale_costo_corso || 0;
    let numero_rate = this.formNuovoPreventivo.value.numero_rate || 1;

    // Calcola il totale del corso scontato

    let sconto_applicato = 0;
    if (this.isDiscountMonthly) {
      sconto_applicato = this.formNuovoPreventivo.value.cifra_sconto_mensile || 0;
    } else {
      let sconto = this.formNuovoPreventivo.value.sconto || 0;
      sconto_applicato = costo_totale_corso * (sconto / 100);
    }
    let costo_totale_corso_scontato = costo_totale_corso - sconto_applicato;

    // Calcola la prima rata e il totale da saldare
    let rata_1 = costo_totale_corso_scontato / numero_rate;
    let totale_saldare = costo_totale_corso_scontato - rata_1;
    let prima_rata = this.totaleMaterialeDidattico + this.totaleTassaIscrizione + rata_1;

    // Calcola le rate rimanenti
    let new_rate = numero_rate - 1;
    let importo = totale_saldare / new_rate;

    // Arrotonda i valori
    let importoRounded = parseFloat(importo.toFixed(2));
    let totaleRounded = parseFloat(totale_saldare.toFixed(2));
    let rataRounded = parseFloat(prima_rata.toFixed(2));

    // Aggiorna i valori nel form
    this.formNuovoPreventivo.patchValue({
      importo_rata: importoRounded,
      totale_saldare: totaleRounded,
      prima_rata: rataRounded,
      rate_rimanenti: new_rate
    });

    // Restituisce i valori per eventuali ulteriori elaborazioni
    return { new_rate, totale_saldare: totaleRounded };
  }

  generateRate4() {
    let costo_totale_corso = this.formNuovoPreventivo.value.totale_costo_corso || 0;

    if (costo_totale_corso == null) {
      this._toastr.warning("Inserisci il costo totale del corso");
    }
    let acconto = this.formNuovoPreventivo.get('acconto').value ? this.formNuovoPreventivo.get('acconto').value : 0;
    this.formNuovoPreventivo.patchValue({
      prima_rata: this.totaleMaterialeDidattico + this.totaleTassaIscrizione + Number(this.formNuovoPreventivo.get('acconto').value)
    });

    // Estrai i valori dal form

    let sconto_applicato = 0;
    if (this.isDiscountMonthly) {
      sconto_applicato = this.formNuovoPreventivo.value.cifra_sconto_mensile || 0;
    } else {
      let sconto = this.formNuovoPreventivo.value.sconto || 0;
      sconto_applicato = costo_totale_corso * (sconto / 100);
    }

    let costo_totale_corso_scontato = (costo_totale_corso - sconto_applicato);
    let costo_totale_con_acconto = costo_totale_corso_scontato - acconto;

    let numero_rate = this.formNuovoPreventivo.value.numero_rate;
    let importo = costo_totale_con_acconto / numero_rate;

    // Arrotonda i valori
    let importoRounded = parseFloat(importo.toFixed(2));
    let totaleRounded = parseFloat(costo_totale_con_acconto.toFixed(2));
    this.formNuovoPreventivo.patchValue({ totale_saldare: costo_totale_con_acconto });

    // Aggiorna i valori nel form
    this.formNuovoPreventivo.patchValue({
      importo_rata: importoRounded,
      numero_rate: numero_rate,
      rate_rimanenti: numero_rate,
      totale_saldare: totaleRounded
    });

    // Restituisce i valori per eventuali ulteriori elaborazioni
    return { numero_rate, totale_saldare: totaleRounded };
  }


  changeTotaleRate() {
    let data = this.generateRate();
    this.formNuovoPreventivo.patchValue({
      numero_rate: data.rate - 1
    });
  }

  //API MODIFICA PREVENTIVO//
  storeEditPreventivo() {
    this.formNuovoPreventivo.patchValue({
      costo_materiale_didattico: this.totaleMaterialeDidattico,
      tassa_iscrizione: this.totaleTassaIscrizione,
    })

    if (this.formNuovoPreventivo.get('sconto_materiale_didattico').value != null) {
      this.formNuovoPreventivo.patchValue({
        check_sconto_materiale: true,
      })
    } else {
      this.formNuovoPreventivo.patchValue({
        check_sconto_materiale: false,
      })
    }

    if (this.formNuovoPreventivo.get('sconto_tassa_iscrizione').value != null) {
      this.formNuovoPreventivo.patchValue({
        check_sconto_tassa: true,
      })
    } else {
      this.formNuovoPreventivo.patchValue({
        check_sconto_tassa: false,
      })
    }

    this.preventivi_service.updatePreventivo({ ...this.formNuovoPreventivo.value, ...{ notes_array: this.note_flags } }).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this._toastr.success("Preventivo modificato con successo");
      this.getAllPreventivi();
      this.getAllContratti();
      this.resetForm();
    })
  }

  //API MODIFICA CONTRATTO//
  onStatoChange(event) {
    if (event.target.value == 'APPROVATO') {
      this.isApproved = true;
    } else {
      this.isApproved = false;
    }
  }

  storeEditContratto() {
    this.formNuovoPreventivo.patchValue({
      costo_materiale_didattico: this.totaleMaterialeDidattico,
      tassa_iscrizione: this.totaleTassaIscrizione,
    })

    if (this.formNuovoPreventivo.get('sconto_materiale_didattico').value != null) {
      this.formNuovoPreventivo.patchValue({
        check_sconto_materiale: true,
      })
    } else {
      this.formNuovoPreventivo.patchValue({
        check_sconto_materiale: false,
      })
    }

    if (this.formNuovoPreventivo.get('sconto_tassa_iscrizione').value != null) {
      this.formNuovoPreventivo.patchValue({
        check_sconto_tassa: true,
      })
    } else {
      this.formNuovoPreventivo.patchValue({
        check_sconto_tassa: false,
      })
    }

    this.preventivi_service.updateContratto({ ...this.formNuovoPreventivo.value, ...{ notes_array: this.note_flags } }).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this._toastr.success("Contratto modificato con successo");
      this.getAllContratti();
      this.resetForm();
    })
  }

  resetForm() {
    // Supponendo che formNuovoPreventivo sia il FormGroup principale
    this.formNuovoPreventivo.reset();

    // Ottenere il FormArray dal FormGroup
    const formArray = this.formNuovoPreventivo.get('array_lingue') as FormArray;

    // Rimuovere tutti gli elementi dal FormArray
    while (formArray.length) {
      formArray.removeAt(0);
    }
  }

  closeModal() {
    this.resetForm();
    this.totaleMaterialeDidattico = 0;
    this.totaleTassaIscrizione = 0;
    this.fl = 'no';
    this.filtro_opzione.next(null);
  }

  chooseOption(event) {

    switch (event.target.value) {
      case '1':
        this.filtro_opzione.next(1);
        this.method.next(PaymentType.UNICA_SOLUZIONE);
        this.formNuovoPreventivo.get('costo_materiale_didattico').reset();
        this.formNuovoPreventivo.get('costo_materiale_didattico').setValue(0);
        this.formNuovoPreventivo.get('tassa_iscrizione').reset();
        this.formNuovoPreventivo.get('tassa_iscrizione').setValue(0);
        this.formNuovoPreventivo.get('sconto_materiale_didattico').reset();
        this.formNuovoPreventivo.get('sconto_tassa_iscrizione').reset();
        this.totaleMaterialeDidattico = 0;
        this.totaleTassaIscrizione = 0;
        this.formNuovoPreventivo.get('totale_costo_corso').reset();
        this.formNuovoPreventivo.get('numero_rate').reset();
        this.formNuovoPreventivo.get('importo_rata').reset();
        this.formNuovoPreventivo.get('totale_saldare').reset();
        this.formNuovoPreventivo.get('sconto').reset();
        this.formNuovoPreventivo.get('acconto').reset();
        this.formNuovoPreventivo.get('prima_rata').reset();
        break;
      case '2':
        this.filtro_opzione.next(2);
        this.method.next(PaymentType.PREVENTIVO_ACCONTO);
        this.formNuovoPreventivo.get('costo_materiale_didattico').reset();
        this.formNuovoPreventivo.get('costo_materiale_didattico').setValue(0);
        this.formNuovoPreventivo.get('tassa_iscrizione').reset();
        this.formNuovoPreventivo.get('tassa_iscrizione').setValue(0);
        this.formNuovoPreventivo.get('sconto_materiale_didattico').reset();
        this.formNuovoPreventivo.get('sconto_tassa_iscrizione').reset();
        this.totaleMaterialeDidattico = 0;
        this.totaleTassaIscrizione = 0;
        this.formNuovoPreventivo.get('totale_costo_corso').reset();
        this.formNuovoPreventivo.get('numero_rate').reset();
        this.formNuovoPreventivo.get('importo_rata').reset();
        this.formNuovoPreventivo.get('totale_saldare').reset();
        this.formNuovoPreventivo.get('sconto').reset();
        this.formNuovoPreventivo.get('acconto').reset();
        this.formNuovoPreventivo.get('prima_rata').reset();
        break;
      case '3':
        this.filtro_opzione.next(3);
        this.method.next(PaymentType.PREVENTIVO_ACCONTO_PRIMA_RATA);
        this.formNuovoPreventivo.get('costo_materiale_didattico').reset();
        this.formNuovoPreventivo.get('costo_materiale_didattico').setValue(0);
        this.formNuovoPreventivo.get('tassa_iscrizione').reset();
        this.formNuovoPreventivo.get('tassa_iscrizione').setValue(0);
        this.formNuovoPreventivo.get('sconto_materiale_didattico').reset();
        this.formNuovoPreventivo.get('sconto_tassa_iscrizione').reset();
        this.totaleMaterialeDidattico = 0;
        this.totaleTassaIscrizione = 0;
        this.formNuovoPreventivo.get('totale_costo_corso').reset();
        this.formNuovoPreventivo.get('numero_rate').reset();
        this.formNuovoPreventivo.get('importo_rata').reset();
        this.formNuovoPreventivo.get('totale_saldare').reset();
        this.formNuovoPreventivo.get('sconto').reset();
        this.formNuovoPreventivo.get('acconto').reset();
        this.formNuovoPreventivo.get('prima_rata').reset();
        break;
      case '4':
        this.filtro_opzione.next(4);
        this.method.next(PaymentType.PREVENTIVO_ACCONTO_MANUALE);
        this.formNuovoPreventivo.get('costo_materiale_didattico').reset();
        this.formNuovoPreventivo.get('costo_materiale_didattico').setValue(0);
        this.formNuovoPreventivo.get('tassa_iscrizione').reset();
        this.formNuovoPreventivo.get('tassa_iscrizione').setValue(0);
        this.formNuovoPreventivo.get('sconto_materiale_didattico').reset();
        this.formNuovoPreventivo.get('sconto_tassa_iscrizione').reset();
        this.totaleMaterialeDidattico = 0;
        this.totaleTassaIscrizione = 0;
        this.formNuovoPreventivo.get('totale_costo_corso').reset();
        this.formNuovoPreventivo.get('numero_rate').reset();
        this.formNuovoPreventivo.get('importo_rata').reset();
        this.formNuovoPreventivo.get('totale_saldare').reset();
        this.formNuovoPreventivo.get('sconto').reset();
        this.formNuovoPreventivo.get('acconto').reset();
        this.formNuovoPreventivo.get('prima_rata').reset();
        break;
      default:
        break;
    }
  }

  // funzione per formattazione custom dei numeri
  formatNumber(value: any) {
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      return '';
    }
    let formattedValue = numericValue.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return `€${formattedValue}`;
  }

  checkTrancheStatus() {
    let tranche_number = this.formNuovoPreventivo.get('numero_rate').value;
    return this.isApproved && (tranche_number != null && tranche_number > 0);
  }

  goTo(path: string, id = null) {
    let url = id ? `${path}/${id}` : path;
    this.router.navigate([url]);
  }

  /**
   * Setta l'id del preventivo
   * @param id 
   */
  setSelectedPreventivo(element: any) {
    this.selectedPreventivoId = element.id;
    this.prev = element;
  }

  /**
   * Lancia l'api per duplicare il preventivo
   */
  onConfirmDuplicate() {
    if (this.selectedPreventivoId) {
      this.preventivi_service.duplicaPreventivo(this.selectedPreventivoId).pipe(takeUntil(this.destroy$)).subscribe({
        next: (response) => {
          this._toastr.success("Preventivo duplicato con successo");
          this.getData();
        },
        error: (error) => {
          console.error('Errore durante la duplicazione del preventivo:', error);
        }
      });
    }
  }

}