<div class="altezza-page px-0 mx-0" id="wrap2">
    <div class="row px-0 mx-0">
        <div class="col-md-2 px-0 mx-0">
            <aside class="bg-color">
                <div class="margine-sup-side padding-logo mb-4">
                    <a routerLink="/dashboard">
                        <img alt="Logo" class="h-25px logo" src="./assets/img/logo-yeschool-1.svg" />
                    </a>
                </div>

                <ng-container *ngFor="let item of sidebar_items">
                    <div class="menu-item" (click)="filterRole(item.value)" onkeydown="">
                        <a class="menu-link without-sub style-link">
                        <span class="menu-icon">
                            <span [inlineSVG]="item.icon" class="svg-icon svg-icon-2"></span>
                        </span>
                            <span class="menu-title">{{item.label}}</span>
                        </a>
                    </div>
                </ng-container>
            </aside>
        </div>

        <div class="col-md-10 margine-sup">

            <div class="section__header">
                <div class="row my-2 ">
                    <div class="col-12 d-flex justify-content-between align-items-center">
                        <h1 class="mb-0 pb-0">Dettaglio {{userForm.get('name').value}} {{userForm.get('surname').value}}</h1>
                        <span style="font-size: 20px;" *ngIf="checkIncludedFields(userForm, 'role', [4]) && current_user.teacher_hours">
                            <b>Totale ore svolte:</b> {{current_user.teacher_hours}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="section__body pt-3">
                <!--GESTIONE TAB-->
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">
                            Anagrafica
                        </button>

                        <ng-container *ngIf="checkIncludedFields(userForm, 'role', [5, 6, 7])">
                            <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="true">
                                Lingue
                            </button>
                            <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">
                                Pagamenti
                            </button>
                        </ng-container>

                        <ng-container>
                            <button *ngIf="checkIncludedFields(userForm, 'role', [4])" class="nav-link" id="nav-class-tab" data-bs-toggle="tab" data-bs-target="#nav-class" type="button" role="tab" aria-controls="nav-class" aria-selected="true">
                                Classi
                            </button>
                            <button *ngIf="checkIncludedFields(userForm, 'role', [4])" class="nav-link" id="nav-conversation-tab" data-bs-toggle="tab" data-bs-target="#nav-conversation" type="button" role="tab" aria-controls="nav-conversation" aria-selected="true">
                                Conversation
                            </button>
                        </ng-container>
                    </div>
                </nav>
                
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <section>                       
                            <p>Data di creazione: {{current_user?.created_at | date: 'dd/MM/yyyy'}}</p>
                        </section>
                        <!-- ANAGRAFICA -->
                        <app-user-form
                            [userForm]="userForm"
                            [roles]="roles"
                            [channels]="channels"
                            [categories]="categories"
                            [subscriptions]="subscriptions"
                            [detail]="true"
                        ></app-user-form>

                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn color-butt mt-5 mb-3 me-4" data-toggle="modal"
                                    data-target="#resetPasswordModal" *ngIf="adminOrStaff && current_user?.user_id"
                                    (click)="resetForm.reset()">
                                Reset password
                            </button>

                            <button type="button" class="btn color-butt mt-5 mb-3" data-dismiss="modal"
                                    (click)="updateAnagraficaMember()" [disabled]="!userForm.valid"
                                    *ngIf="adminOrStaff">
                                Aggiorna
                            </button>
                        </div>

                        <!-- * GESTIONE ANAGRAFICA PIVOT -->
                        <div class="mt-4" *ngIf="checkIncludedFields(userForm, 'role', [5, 6, 7]) && original_role == userForm.get('role').value">
                            <h1 class="mt-4">Anagrafica
                                <ng-container [ngSwitch]="userForm.get('role').value">
                                    <span *ngSwitchCase="5"> genitore</span>
                                    <span *ngSwitchCase="6"> figli</span>
                                    <span *ngSwitchCase="7"> dipendenti</span>
                                </ng-container>
                            </h1>

                            <div class="row d-flex">
                                <div class="col-4">
                                    <app-input [type]="'autocomplete'" [formcontrol]="representativeControl" [values]="representatives" [roleType]="'role6'" (autocompleteClick)="handleAutocompleteClick($event)"></app-input>
                                </div>

                                <button type="button" class="btn color-butt col-1 custom-button" (click)="setRepresentative()" [disabled]="representativeControl.invalid">
                                    Aggiungi
                                </button>
                            </div>

                            <!-- * Aggiunta dell'azienda lato studente -->
                            <div *ngIf="checkIncludedFields(userForm, 'role', [5])">
                                <h1 class="mt-4">Anagrafica
                                    <ng-container [ngSwitch]="userForm.get('role').value">
                                        <span *ngSwitchCase="5"> azienda</span>
                                    </ng-container>
                                </h1>

                                <div class="row d-flex">
                                    <div class="col-4">
                                        <app-input [type]="'autocomplete'" [formcontrol]="representativeControl" [values]="representatives" [roleType]="'role7'" (autocompleteClick)="handleAutocompleteClick($event)"></app-input>
                                    </div>
    
                                    <button type="button" class="btn color-butt col-1 custom-button" (click)="setRepresentative()" [disabled]="representativeControl.invalid">
                                        Aggiungi
                                    </button>
                                </div>
                            </div>
                            

                            <ng-container 
                                [ngTemplateOutlet]="anagraficaPivotInfo" 
                                [ngTemplateOutletContext]="{items: current_user?.anagrafica_pivot}">
                            </ng-container>
                                
                            <ng-template #anagraficaPivotInfo>
                                <div class="mt-3" *ngFor="let item of current_user?.anagrafica_pivot">
                                    <h4 class="representative-name">
                                        <span (click)="router.navigate(['anagrafica/info-anagrafica/' + item.id])" onkeydown="">{{item.name}} {{item.surname}}</span>
                                        <i (click)="deleteRepresentative(item.id)" onkeydown="" style="color: red" class="fa fa-trash ms-2" matTooltip="Rimuovi" matTooltipPosition="above"></i>
                                    </h4>

                                    <ul>
                                        <li>
                                            <strong>Telefono</strong>: {{item.phone ?? 'Non definito'}}
                                        </li>

                                        <li>
                                            <strong>Email</strong>: {{item.email ?? 'Non definita'}}
                                        </li>
                                    </ul>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    
                    <!-- ? TAB LINGUE-->
                    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <!-- Button trigger modal -->
                        <div class="d-flex justify-content-between align-items-center">
                            <h1 class="py-0 my-0">Lingue</h1>
                            <button type="button" class="btn color-butt my-2" data-toggle="modal"
                                    data-target="#exampleModal" *ngIf="adminOrStaff" (click)="funzionew1()">
                                Aggiungi Lingua
                            </button>
                        </div>
                        <div *ngIf="(total_lingue | async) == 0" class="mt-4">
                            <div class="no-cont py-4"><i class="f-16">Nessuna lingua richiesta</i> </div>
                        </div>
                        <table *ngIf="(total_lingue | async) > 0" mat-table [dataSource]="(lingue|async)"
                               class="tb-bord">
                            <!-- Name Column -->
                            <ng-container matColumnDef="lingua_richiesta">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Lingua
                                    richiesta </th>
                                <td mat-cell *matCellDef="let element">{{element?.ling_richiesta}}</td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="lingua_partenza">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Livello
                                    partenza</th>
                                <td mat-cell *matCellDef="let element"> {{element?.ling_partenza}}</td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="certificazione">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef>
                                    Certificazione </th>
                                <td mat-cell *matCellDef="let element">{{element?.certificazioni}} </td>
                            </ng-container>
                            <ng-container matColumnDef="azione">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> <span
                                        *ngIf="adminOrStaff">Azione</span></th>
                                <td mat-cell *matCellDef="let element">
                                    <button class="btn btn-outline-danger" data-toggle="modal" *ngIf="adminOrStaff"
                                            (click)="setMemberDeleteLingua(element)" data-target="#deleteLanguage">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                            <path
                                                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                        </svg>
                                    </button>
                                    <button class="btn btn-outline-dark" (click)="setMemberLingua(element)"
                                            *ngIf="adminOrStaff" data-toggle="modal" data-target="#editLanguage">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                            <path
                                                    d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                        </svg>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>

                        <mat-paginator [length]="(total_lingue | async)" [pageSize]="pageSize2"
                                       [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent2 = handlePage2($event)"
                                       showFirstLastButtons>
                        </mat-paginator>
                    </div>

                    <!-- Modal Aggiunta -->
                    <form [formGroup]="addLinguaForm">
                        <div class="modal fade" id="exampleModal" tabindex="-1"
                             aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title" id="exampleModalLabel">Aggiungi Lingua</h1>
                                        <button type="button" class="btn-close" data-dismiss="modal"
                                                aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div>
                                            <div class="form-group mb-3">
                                                <label for="inputLevel"><b>Lingua desiderata</b></label>
                                                <select class="form-select form-select-lg"
                                                        aria-label="Default select example" formControlName="ling_richiesta"
                                                        (change)="getAllCertifications($event)">
                                                    <option *ngFor="let value of (languages | async)"
                                                            value="{{value.nome_lingua}}">
                                                        {{value.nome_lingua}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group mb-3">
                                                <label for="inputLevel"><b>Livello di Partenza</b></label>
                                                <select class="form-select form-select-lg"
                                                        aria-label="Default select example" formControlName="ling_partenza">
                                                    <option *ngFor="let value of (levels)" value="{{value.nome}}">
                                                        {{value.nome}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group mb-3">
                                                <label for="inputLevel"><b>Certificazione</b></label>
                                                <select class="form-select form-select-lg"
                                                        aria-label="Default select example"
                                                        formControlName="certificazioni">
                                                    <option *ngFor="let value of (allCertifications | async)"
                                                            value="{{value.nome}}">
                                                        {{value.nome}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <div class="d-flex justify-content-between mt-5">
                                            <button class="btn btn-danger" data-dismiss="modal">Annulla</button>
                                            <button class="btn color-butt" data-dismiss="modal"
                                                    (click)="storeLingue()">Salva</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!--MODALE DI MODIFICA LINGUA-->
                    <form [formGroup]="editLinguaForm" *ngIf="(allCertifications | async) && (languages | async)">
                        <div class="modal fade" id="editLanguage" tabindex="-1"
                             aria-labelledby="editLanguageLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title" id="editLanguageLabel">Modifica Lingua</h1>
                                        <button type="button" class="btn-close" data-dismiss="modal"
                                                aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div>
                                            <div class="form-group mb-3">
                                                <label for="inputLevel"><b>Lingua desiderata</b></label>
                                                <select class="form-select form-select-lg"
                                                        aria-label="Default select example" formControlName="ling_richiesta"
                                                        (change)="getAllCertifications($event)">
                                                    <option *ngFor="let value of (languages | async)"
                                                            value="{{value.nome_lingua}}">
                                                        {{value.nome_lingua}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group mb-3">
                                                <label for="inputLevel"><b>Lingua di Partenza</b></label>
                                                <select class="form-select form-select-lg"
                                                        aria-label="Default select example" formControlName="ling_partenza">
                                                    <option *ngFor="let value of (levels)" value="{{value.nome}}">
                                                        {{value.nome}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group mb-3">
                                                <label for="inputLevel"><b>Certificazione</b></label>
                                                <select class="form-select form-select-lg"
                                                        aria-label="Default select example"
                                                        formControlName="certificazioni">
                                                    <option *ngFor="let value of (allCertifications | async)"
                                                            [ngValue]="value.nome.trim()">
                                                        {{value.nome}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <div class="d-flex justify-content-between mt-5">
                                            <button class="btn btn-danger" data-dismiss="modal">Annulla</button>
                                            <button class="btn color-butt" data-dismiss="modal"
                                                    (click)="editLingua()">Modifica Lingua</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <!-- ? TAB PAGAMENTI -->
                    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">

                        <div *ngIf="(records3 | async) == 0">
                            <div class="no-cont py-4 f-16"><i class="f-16">Nessun pagamento riscontrato</i> </div>
                        </div>

                        <table *ngIf="(records3 | async) > 0" mat-table [dataSource]="(pagamenti | async)"
                               class="tb-bord">
                            <!-- Rata Column -->
                            <ng-container matColumnDef="rata">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Rata </th>
                                <td mat-cell *matCellDef="let element"> {{element.rata}} </td>
                            </ng-container>

                            <!-- Importo Column -->
                            <ng-container matColumnDef="importo">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Importo </th>
                                <td mat-cell *matCellDef="let element"> {{(element.importo | currency:
                                    'EUR':'symbol')?.replace('.',',')}} </td>
                            </ng-container>

                            <!-- Data Column -->
                            <ng-container matColumnDef="data">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Data
                                    Pagamento </th>
                                <td mat-cell *matCellDef="let element">
                                    <p *ngIf="element.data_pagamento!=null">{{element.data_pagamento}} </p>
                                    <p *ngIf="element.data_pagamento==null">Non presente</p>
                                </td>
                            </ng-container>

                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Saldato </th>
                                <td mat-cell *matCellDef="let element">
                                    <button type="button"
                                            [ngClass]="checked(element.id) ? 'btn btn-success green' : 'btn btn-danger'"
                                            data-toggle="modal" data-target="#ModalPaga" (click)="setRata(element)"
                                            [disabled]="variabile_button2">{{variabile_button}}</button>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" style="text-align: center;"
                                    [attr.colspan]="displayedColumns2.length">Nessun elemento trovato
                                </td>
                            </tr>
                        </table>
                        <mat-paginator [length]="(records3 | async)" [pageSize]="pageSize2"
                                       [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent2 = handlePage2($event)"
                                       showFirstLastButtons>
                        </mat-paginator>
                    </div>

                    <!-- ? TAB CLASSI -->
                    <div class="tab-pane fade" id="nav-class" role="tabpanel" aria-labelledby="nav-class-tab">
                        <div *ngIf="(classiDocente | async) == 0">
                            <div class="no-cont py-4 f-16"><i class="f-16">Nessuna classe assegnata</i> </div>
                        </div>
                        <cdk-accordion class="example-accordion">
                            <cdk-accordion-item *ngFor="let item of (classiDocente | async); let index = index;"
                                                #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button"
                                                tabindex="0" [attr.id]="'accordion-header-' + index"
                                                [attr.aria-expanded]="accordionItem.expanded"
                                                [attr.aria-controls]="'accordion-body-' + index" onkeydown="">
                                <div class="example-accordion-item-header" (click)="accordionItem.toggle()"
                                     onkeydown="">
                                    Nome classe: {{ item.classe_assegnata.nome_classe }}
                                    <span class="example-accordion-item-description">
                                        {{ accordionItem.expanded ? 'Chiudi dettaglio' : 'Accedi al dettaglio' }}
                                    </span>
                                </div>
                                <div class="example-accordion-item-body" role="region"
                                     [style.display]="accordionItem.expanded ? '' : 'none'"
                                     [attr.id]="'accordion-body-' + index"
                                     [attr.aria-labelledby]="'accordion-header-' + index">
                                    <div class="d-flex flex-column">
                                        <div class="d-flex align-items-center">
                                            <h3>Lingua corso: {{item.classe_assegnata.lingua_corso}}</h3>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <h3>Data inizio: {{item.classe_assegnata.data_inizio | date:'dd/MM/YYYY'}}
                                            </h3>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <h3>Data fine: {{item.classe_assegnata.data_fine | date:'dd/MM/YYYY'}}</h3>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <button class="btn-go" (click)="goTo(item.classe_id)">Vai alla classe
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </cdk-accordion-item>
                        </cdk-accordion>
                    </div>

                    <!-- ? TAB CONVERSATION -->
                    <div class="tab-pane fade" id="nav-conversation" role="tabpanel"
                         aria-labelledby="nav-conversation-tab">
                        <div *ngIf="(conversationDocente | async) == 0">
                            <div class="no-cont py-4 f-16"><i class="f-16">Nessuna conversation assegnata</i> </div>
                        </div>
                        <cdk-accordion class="example-accordion">
                            <cdk-accordion-item *ngFor="let item of (conversationDocente | async); let index = index;"
                                                #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button"
                                                tabindex="0" [attr.id]="'accordion-header-' + index"
                                                [attr.aria-expanded]="accordionItem.expanded"
                                                [attr.aria-controls]="'accordion-body-' + index" onkeydown="">
                                <div class="example-accordion-item-header" (click)="accordionItem.toggle()"
                                     onkeydown="">
                                    Nome conversation: {{ item.conversation.titolo }}
                                    <span class="example-accordion-item-description">
                                        {{ accordionItem.expanded ? 'Chiudi dettaglio' : 'Accedi al dettaglio' }}
                                    </span>
                                </div>
                                <div class="example-accordion-item-body" role="region"
                                     [style.display]="accordionItem.expanded ? '' : 'none'"
                                     [attr.id]="'accordion-body-' + index"
                                     [attr.aria-labelledby]="'accordion-header-' + index">
                                    <div class="d-flex flex-column">
                                        <div class="d-flex align-items-center">
                                            <h3>Lingua conversation: {{item.conversation.lingua}}</h3>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <button class="btn-go" (click)="goToConversation(item.conversation_id)">Vai
                                                alla conversation </button>
                                        </div>
                                    </div>
                                </div>
                            </cdk-accordion-item>
                        </cdk-accordion>
                    </div>

            </div>
        </div>
    </div>
</div>
    
<!-- ! Modal Delete Lingua  -->
<div class="modal fade bd-example-modal-sm" id="deleteLanguage" tabindex="-1"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm mod-width">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">Elimina lingua</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-4">
                <p class="f-16 mb-3">Eliminare definitivamente {{deleteMemberLingua?.ling_richiesta}}</p>
                <div class="d-flex justify-content-between mt-5">
                    <button class="btn color-butt" data-dismiss="modal">Annulla</button>
                    <button class="btn btn-danger" data-dismiss="modal"
                        (click)="deleteLingua(deleteMemberLingua?.id)">Elimina</button>
                </div>
            </div>
        </div>
    </div>
</div>
    
<!-- Modal PAGA -->
<div class="modal fade bd-example-modal-sm" id="ModalPaga" tabindex="-1"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm mod-width">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">Conferma pagamento rata</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="f-16 mb-3">Pagamento della rata con importo {{(rata?.importo | currency:
                    'EUR':'symbol')?.replace('.',',')}}</p>
                <div>
                    <h3>Data di pagamento</h3>
                    <mat-form-field color="accent" appearance="fill">
                        <input matInput [matDatepicker]="picker2" [(ngModel)]="data_pagamento">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 color="primary"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="d-flex justify-content-between mt-5">
                    <button class="btn color-butt" data-dismiss="modal">Annulla</button>
                    <button class="btn btn-success" data-dismiss="modal" (click)="setPayment(rata)">Conferma</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- MODALE PER RESET PASSWORD -->
<div class="modal fade" id="resetPasswordModal" tabindex="-1" aria-labelledby="resetPasswordModal" aria-hidden="true">
    <div class="modal-dialog modal-sm mod-width">
        <div class="modal-content pb-2">
            <div class="modal-header ps-0 pe-0 pb-3 border-bottom">
                <h2 class="modal-title">Reset password</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="resetForm" (ngSubmit)="onResetPassword()">
                    <div class="row fv-row mb-7">
                        <app-input [type]="'input'" [inputType]="'password'" [label]="'Nuova password'" [form]="resetForm" [control]="'password'"></app-input>
                    </div>

                    <div class="row fv-row mb-7">
                        <app-input [type]="'input'" [inputType]="'password'" [label]="'Conferma password'" [form]="resetForm" [control]="'password_confirmation'"></app-input>
                    </div>

                    <password-criterias [control]="resetForm.get('password')"></password-criterias>

                    <div class="d-flex justify-content-end">
                        <button type="submit" (click)="onResetPassword()" class="btn color-butt" data-dismiss="modal"
                            [disabled]="resetForm.invalid">
                            Aggiorna
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>