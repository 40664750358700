import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AnagraficaService } from 'src/app/services/anagraphic.service';
import { confirmPasswordValidator } from "../../utilities/validators/confirm-password-validator";
import { password_regex } from "../../utilities/regex";

@Component({
  selector: 'app-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.scss']
})
export class ProfiloComponent implements OnInit, OnDestroy {
  passwordForm: FormGroup = this.initPasswordForm();
  emailForm: FormGroup = this.initEmailForm();

  passwordFormErrors: Array<string> = [];
  emailFormErrors: Array<string> = [];

  profiloForm: FormGroup;
  imageForm: FormGroup;
  messageForm: FormGroup;
  id;
  profilo;
  profilo_name: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  messaggi_scritti: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private _anagraficaService: AnagraficaService,
    private dateAdapter: DateAdapter<Date>,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef
  ) {
    this.dateAdapter.setLocale('it-IT');
    this.profiloForm = this.fb.group({
      id: [null],
      user_id: [null],
      name: [null, Validators.required],
      surname: [null],
      birth_date: [null],
      // birth_date: [moment().format('YYYY-MM-DD'), Validators.required],
      phone: [null],
    });

    this.imageForm = this.fb.group({
      id: [null],
      image: [null]
    });
    this.messageForm = this.fb.group({
      messaggio: [null],
      user_id: [null],
      created_at: [moment().format('YYYY-MM-DD')]
    });
  }

  ngOnInit(): void {
    this.id = localStorage.getItem('user_id');
    $('.tooltipevent').remove();
    this.getProfile();
    this.getMessages();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFormattedDate(date: moment.MomentInput, format: string | undefined) {
    return moment(date).locale('it').format(format);
  }

  checkDate() {
    let value = this.profiloForm.get('birth_date').value;
    let isValid = false;

    if (value != null) {
      let birthDate = moment(value);
      let today = moment();
      isValid = birthDate <= today;
    }

    if (!isValid) {
      this.toastr.error('Inserire una data di nascita valida');
    }

    return isValid;
  }


  getProfile() {
    this._anagraficaService.getProfile({ id: this.id }).pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.result) {
        this.profilo = response.result;
        this.profilo_name.next(response.result);
        this.profiloForm.patchValue({
          id: this.profilo.id,
          user_id: this.id,
          name: this.profilo.name,
          surname: this.profilo.surname,
          birth_date: this.profilo.birth_date != null ? this.getFormattedDate(this.profilo.birth_date, 'YYYY-MM-DD') : null,
          phone: this.profilo.phone,
        });
      }
    });
  }

  editData() {
    let checkDateValid = this.checkDate();

    if (checkDateValid) {
      if (this.profiloForm.valid) {
        this._anagraficaService.editProfile(this.profiloForm.value).pipe(takeUntil(this.destroy$)).subscribe(
          response => {
            if (response.result) {
              this.toastr.success('Dati aggiornati con successo');
              this.profiloForm.reset();
              this.ngOnInit();
            }
          }
        )
      } else {
        this.toastr.error('Compilare tutti i campi dei dati personali');
      }
    }
  }

  editImage() {
    this.passwordForm.value.user_id = this.profilo.user_id;
    this._anagraficaService.editPassword(this.passwordForm.value).pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.result) {
          this.toastr.success('Password aggiornata con successo');
          this.profiloForm.reset();
          this.ngOnInit();
        }
      }
    )
  }

  handleFileInput(files: any) {
    this.imageForm.patchValue({
      id: this.profilo.user_id,
      image: (files as HTMLInputElement).files?.item(0)
    });
  }

  savephoto() {
    this._anagraficaService.editImage(this.imageForm.value).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.toastr.success("Foto modificata con successo");
      window.location.reload();
    })
  }

  addMessage() {
    this.messageForm.patchValue({
      created_at: this.getFormattedDate(this.messageForm.value.data_inizio, 'YYYY-MM-DD'),
      user_id: this.profilo.user_id
    });
    this._anagraficaService.insertMessage(this.messageForm.value).pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.result) {
          this.toastr.success('Messaggio inserito con successo');
          this.messageForm.reset();
          this.getMessages();
        }
      }
    )
  }

  getMessages() {
    this._anagraficaService.getMessage().pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.result) {
          this.messaggi_scritti.next(response.result);
        }
      }
    )
  }

  /**
   * Inizializza il form per il cambio password
   */
  initPasswordForm() {
    return new FormGroup({
      old_password: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.compose([Validators.required, Validators.pattern(password_regex())])),
      password_confirmation: new FormControl(null, Validators.required)
    }, { validators: confirmPasswordValidator });
  }

  /**
   * Inizializza il form per il cambio email
   */
  initEmailForm() {
    return new FormGroup({
      email: new FormControl(null, Validators.compose([Validators.required, Validators.email])),
      new_email: new FormControl(null, Validators.compose([Validators.required, Validators.email]))
    });
  }

  /**
   * Cambio password
   */
  editPassword() {
    this.passwordFormErrors = [];

    this._anagraficaService.editPassword(this.passwordForm.value).pipe(takeUntil(this.destroy$), finalize(() => this.cd.detectChanges())).subscribe({
      next: response => {
        this.toastr.success(response.message);
        this.passwordForm.reset();
      },
      error: response => {
        this.passwordFormErrors = response.errors;
      }
    });
  }

  /**
   * Cambio email
   */
  editMail() {
    this.emailFormErrors = [];

    this._anagraficaService.editMail(this.emailForm.value).pipe(takeUntil(this.destroy$), finalize(() => this.cd.detectChanges())).subscribe({
      next: response => {
        this.toastr.success(response.message);
        this.emailForm.reset();
      },
      error: response => {
        this.emailFormErrors = response.errors;
      }
    });
  }
}
