import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AnagraficaService } from 'src/app/services/anagraphic.service';
import { ContabilitaService } from 'src/app/services/contabilita.service';

@Component({
  selector: 'app-contabilita',
  templateUrl: './contabilita.component.html',
  styleUrls: ['./contabilita.component.scss']
})
export class ContabilitaComponent implements OnInit {
  pagamenti = [
    { label: 'Contanti', value: 'Contanti' },
    { label: 'Bonifico', value: 'Bonifico' },
    { label: 'Carte di credito', value: 'Carte di credito' }
  ];

  unsubscriber: Subject<void> = new Subject();

  sectionName: string = 'Tutti';
  sectionLoading: boolean = false;
  isEdit: boolean = false;
  noResult: boolean = false;
  totaleEntrate = 0;
  totaleUscite = 0;
  differenzaTotali = 0;

  currentPage = 0;
  currentPage2 = 0;
  pageSize = 100;
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  records2: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  displayedColumns: string[] = ['data', 'utente', 'intestazione', 'oggetto', 'ricevuta', 'importo', 'mezzo', 'azione'];
  data_input: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  data_output: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  inputAddForm: FormGroup;
  dateForm: FormGroup;
  statusForm: FormGroup;
  filter: any;
  filterSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  searchMemberSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  entrate: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  data;
  pageEvent: PageEvent;
  deleteContent: any;
  studente_settato;
  inOutFlag = [{ label: 'Entrata', valore: 0 }, { label: 'Uscita', valore: 1 }];
  elencoAnagrafica: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  flagFilter = null;
  nessuno = { id: null, name: 'Nessuno', surname: '' };
  stati = [
    { id: 'Saldato', label: 'Saldato' },
    { id: 'Non saldato', label: 'Non saldato' },
  ];
  visualizzaTutti = false;
  tutti: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  data_inizio;
  data_fine;
  minEndDate: any;
  statusFilter: string | null = null;

  constructor(
    private fb: FormBuilder,
    private _contabilitaService: ContabilitaService,
    private _anagraficaService: AnagraficaService,
    private toastr: ToastrService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('it-IT');
    this.inputAddForm = this.fb.group({
      id: [null],
      anagrafica_id: [null],
      intestazione: [null, Validators.required],
      data: [moment().format('YYYY-MM-DD'), Validators.required],
      ricevuta: [null],
      oggetto: [null],
      importo: [null, Validators.required],
      stato: [null, Validators.required],
      iva: null,
      flag: [null, Validators.required],
      search_member: [null, Validators.required],
      mezzo: [null]
    });

    this.dateForm = this.fb.group({
      data_inizio: [null],
      data_fine: [null]
    });

    this.dateForm.get('data_inizio').valueChanges.subscribe(value => {
      this.minEndDate = value;
    });

    this.statusForm = this.fb.group({
      status: [null]
    })
  }

  buttonList = [
    { id: 1, value: 'Tutti', flag: null },
    { id: 2, value: 'Entrate', flag: 0 },
    { id: 3, value: 'Uscite', flag: 1 },
  ]

  statusOptions = [
    { value: null, label: "Tutti" },
    { value: "Saldato", label: "Saldati" },
    { value: "Non saldato", label: "Non saldati" },
  ]

  ngOnInit(): void {
    $('.tooltipevent').remove();
    this.delay();
    this.getMembers();

    this.dateForm.patchValue({ data_inizio: this.getCurrentMonthDates().startDate });
    this.dateForm.patchValue({ data_fine: this.getCurrentMonthDates().endDate });
    this.getData();

    this.tableSearchFilterSubscribe();
    this.userSearchFilterSubscribe()
    this.getCurrentMonthDates();

    this.statusFilterSubscribe();
  }

  /**
   * Get della data del primo e ultimo giorno del mese
   * @returns 
   */
  getCurrentMonthDates() {
    let currentDate = new Date();
    let startDateValue = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    let endDateValue = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    return { startDate: this.formatDate(startDateValue), endDate: this.formatDate(endDateValue) };
  }

  /**
   * Change date mat date picker
   */
  onStartDateChange(): void {
    const startDate = this.dateForm.get('data_inizio').value;
    if (startDate) {
      this.minEndDate = startDate;
    }
  }

  /**
   * Formattazione date
   * @param date 
   * @returns 
   */
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  /**
   * Get user
   */
  getMembers() {
    this._anagraficaService.getMembers().pipe(takeUntil(this.unsubscriber)).subscribe(response => {
      if (response.result) {
        response.result.unshift(this.nessuno);
        this.elencoAnagrafica.next(response.result);
      }
    });
  }

  /**
   * Funzione triggerata all'inserimento di un valore nella ricerca utente
   * @param event 
   */
  searchAll(event: Event) {

    let input = event.target as HTMLInputElement;
    this.searchMemberSubject.next(input.value);
  }

  /**
   * Get lista utenti cercati
   */
  getUserSelectList() {

    this.visualizzaTutti = false;
    let params = { like_search: this.inputAddForm.value.search_member };

    if (params.like_search != null && params.like_search != '') {

      this._anagraficaService.searchAll(params).subscribe(response => {
        if (response.result) {
          this.tutti.next(response.result);
          this.noResult = this.tutti.value.length == 0;
          this.visualizzaTutti = true
        }
      })
    }
  }

  /**
   * Sottoscrizione per rilevare cambiamenti nella ricerca utenti transazione
   */
  userSearchFilterSubscribe() {

    this.searchMemberSubject.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.unsubscriber)
    ).subscribe(() => {
      this.getUserSelectList();
    });
  }

  /**
   * Set user
   * @param stud 
   */
  setMember(stud) {
    this.visualizzaTutti = false;

    this.inputAddForm.patchValue({
      anagrafica_id: stud.id,
      search_member: stud.name + " " + stud.surname
    })
  }

  /**
   * Set filtro date 
   */
  setDate() {
    this.dateForm.patchValue({
      data_inizio: this.getFormattedDate(this.dateForm.value.data_inizio, 'YYYY-MM-DD'),
      data_fine: this.getFormattedDate(this.dateForm.value.data_fine, 'YYYY-MM-DD')
    });
    if (this.dateForm.value.data_inizio > this.dateForm.value.data_fine) {
      this.toastr.error('Data fine antecedente alla data di inizio');
    } else {
      this.getData();
    }
  }

  /**
   * Reset filtro date
   */
  resetDate() {

    this.dateForm.patchValue({ data_inizio: this.getCurrentMonthDates().startDate });
    this.dateForm.patchValue({ data_fine: this.getCurrentMonthDates().endDate });
    this.minEndDate = null;

    this.getData()
  }

  /**
   * Sottoscrizione al cambiamento del filtro "stato"
   */
  statusFilterSubscribe() {
    this.statusForm.get('status').valueChanges.pipe(takeUntil(this.unsubscriber)).subscribe((value) => {
      this.statusFilter = value;
      this.getData();
    })
  }

  /**
   * Get dati table transazioni
   */
  getData() {
    let params = this.filter ? { flag: this.flagFilter, start: this.currentPage * this.pageSize, length: this.pageSize, like_search: this.filter, data_inizio: this.dateForm.value.data_inizio, data_fine: this.dateForm.value.data_fine, status: this.statusFilter } : { flag: this.flagFilter, start: this.currentPage * this.pageSize, length: this.pageSize, data_inizio: this.dateForm.value.data_inizio, data_fine: this.dateForm.value.data_fine, status: this.statusFilter };

    this._contabilitaService.getTable(params).pipe(takeUntil(this.unsubscriber)).subscribe(response => {
      if (response.result) {
        this.entrate.next(response.result);
        this.data = new MatTableDataSource(response.result);
        this.records.next(response.total);

        this.sectionLoading = false;

        this.totaleEntrate = response.transaction_total?.totaleEntrate;
        this.totaleUscite = response.transaction_total?.totaleUscite;
        this.differenzaTotali = response.transaction_total?.differenzaTotali;
      }
    });
  }

  /**
   * Sottoscrizione per rilevare cambiamenti nella ricerca dati tabella
   */
  tableSearchFilterSubscribe() {

    this.filterSubject.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.unsubscriber)
    ).subscribe(() => {
      this.getData();
    });
  }

  /**
   * chiamata con delay per ricerca dati tabella
   * @param event
   */
  setTableSearchValue(event: Event) {

    let input = event.target as HTMLInputElement;
    this.filterSubject.next(input.value);
  }

  /**
  * funzione per format date
  * @param date
  * @param format
  * @return
  */
  getFormattedDate(date: moment.MomentInput, format: string | undefined) {
    return moment(date).locale('it').format(format);
  }

  /**
     * Salvataggio transazione
     */
  addEntrata() {
    this.inputAddForm.patchValue({
      data: this.getFormattedDate(this.inputAddForm.value.data, 'YYYY-MM-DD')
    });

    this._contabilitaService.addInput(this.inputAddForm.value).pipe(takeUntil(this.unsubscriber)).subscribe(
      response => {
        if (response.status == true) {
          this.inputAddForm.reset();
          this.toastr.success('Aggiunta con successo');
          this.getData();
        }
        else {
          this.toastr.error(response.message);
        }
      }
    );
  }

  /**
   * Edit transazione
   * @param transactionData
   */
  editTransazione(transactionData: any) {
    this.patchInputForm(transactionData);
    this.isEdit = true;
  }

  /**
  * Patch field form transazione
  * @param inputData
  */
  patchInputForm(inputData: any) {

    this.inputAddForm.patchValue({

      id: inputData.id,
      anagrafica_id: inputData.anagrafica_id,
      intestazione: inputData.intestazione,
      data: this.getFormattedDate(inputData.data, 'YYYY-MM-DD'),
      ricevuta: inputData.ricevuta,
      oggetto: inputData.oggetto,
      importo: inputData.importo,
      stato: inputData.stato,
      iva: inputData.iva,
      flag: inputData.flag,
      search_member: `${inputData.elenco_anagrafica?.name} ${inputData.elenco_anagrafica?.surname}`,
      mezzo: inputData.mezzo
    })
  }

  /**
   * Patch all'apertura della modale
   */
  openModal() {
    this.inputAddForm.patchValue({ flag: this.flagFilter });
  }

  /**
   * Reset form alla chiusura della modale
   */
  resetModal() {
    this.inputAddForm.reset();
    this.isEdit = false;
    this.noResult = false;
    this.visualizzaTutti = false;
  }

  /**
   * Salvataggio edit transazione
   */
  saveEditEntrata() {
    this._contabilitaService.updateInput(this.inputAddForm.value).pipe(takeUntil(this.unsubscriber)).subscribe({
      next: (response) => {

        this.inputAddForm.reset();
        this.toastr.success(response.message);
        this.isEdit = false;
        this.getData();
      },
      error: (err) => {
        this.toastr.error(err.message);
      }
    }
    )
  }

  /**
   * Set delete
   * @param member 
   */
  setDeleteEntrata(member: any) {
    if (this.deleteContent) {
      this.deleteContent = null;
      this.deleteContent = member;

    } else {
      this.deleteContent = member;
    }
    this.ngOnInit();
  }

  /**
   * Delete transazione
   * @param id 
   */
  deleteEntrata(id: any) {
    this._contabilitaService.deleteInput({ id: id }).pipe(takeUntil(this.unsubscriber)).subscribe(
      response => {
        if (response) {
          this.toastr.success('Rimozione avvenuta con successo');
          this.getData();
        }
      }
    )
  }

  /**
   * Set student
   * @param member 
   */
  setStudente(member: any) {
    this.studente_settato = member;
  }

  /**
   * Funzione per formattazione custom dei numeri
   * @param value 
   * @returns 
   */
  formatNumber(value: any) {

    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      return '';
    }
    let formattedValue = numericValue.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return `€${formattedValue}`;
  }

  /**
   * Set della sezione selezionata
   * @param section 
   * @returns 
   */
  setSection(section: any) {
    this.sectionName = section.value;
    this.flagFilter = section.flag;

    this.records.next(0);
    this.entrate.next(null);
    this.sectionLoading = true;
    this.getData();

    return section.flag;
  }

  /**
  * Set delay
  */
  delay() {
    setTimeout(function () {
      let wrap_page = document.getElementById('wrap2')
      wrap_page.classList.remove("d-none");
    }, 500)
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getData();
    return e;
  }

  ngOnDestroy() {
    this.unsubscriber.next()
    this.unsubscriber.complete()
  }

}